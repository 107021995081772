import { Link, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CustomPopOver } from './CustomPopOver';

export const Engineering = () => {
	return (
		<Box>
			<CustomPopOver
				text={'Roofers Union'}
				linkUrl={'https://www.youtube.com/watch?v=-NIFHmSzXuA'}
				imageUrl={
					'https://images.squarespace-cdn.com/content/v1/5b0dd7581aef1d319395b854/1578940081491-07EPN4WXLSOO8YMUUMC7/image-asset.jpeg'
				}
				description="engineering, production, mixing, bass guitar"
			/>
			<CustomPopOver
				text={'Daneshevskaya'}
				linkUrl={
					'https://daneshevskaya.bandcamp.com/album/bury-your-horses-2'
				}
				imageUrl={'https://f4.bcbits.com/img/a2961779798_10.jpg'}
				description="engineering, production, mixing, bass guitar, guitar"
			/>
			<CustomPopOver
				text={'lazylazy'}
				linkUrl={'https://lazylazymusic.bandcamp.com/track/u-feel'}
				imageUrl={'https://f4.bcbits.com/img/a3274815473_10.jpg'}
				description="mixing"
			/>
			<CustomPopOver
				text={'Sedona'}
				linkUrl={'https://sedonasoundslike.bandcamp.com/track/idkyn'}
				imageUrl={
					'https://i.scdn.co/image/ab67616d0000b273e54367e45abc656229dc15eb'
				}
				description="addtl. production, mixing"
			/>

			<CustomPopOver
				text={'Rebounder'}
				linkUrl={'https://rebounder.bandcamp.com/track/night-sports'}
				imageUrl={'https://f4.bcbits.com/img/a0033271837_10.jpg'}
				description="mixing"
			/>
		</Box>
	);
};
