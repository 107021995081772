import { Box, IconButton, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import Video from './Video';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CustomPopOver } from './CustomPopOver';

type CompositionProps = {
	style?: React.CSSProperties;
};

const Composition = ({ style }: CompositionProps) => {
	const [page, setPage] = useState(1);

	const url: string =
		page === 1
			? 'https://player.vimeo.com/video/324849713?h=6c7a3577e4'
			: 'https://player.vimeo.com/video/351181309?h=7f282a5c1b';

	return (
		<Box style={style} flexDirection={'column'}>
			<CustomPopOver
				text={'Bailey Swan (Short Documentary)'}
				linkUrl={
					'https://player.vimeo.com/video/324849713?h=6c7a3577e4'
				}
				imageUrl={
					'https://images.squarespace-cdn.com/content/v1/5e1ce82d78344768bdc83008/1579035218437-5QUMMDDXI9JHVN9AU686/Screen+Shot+2020-01-14+at+2.23.14+PM.png?format=1000w'
				}
			/>
			<CustomPopOver
				text={'Gucci x Garage Magazine: Getaway'}
				linkUrl={
					'https://player.vimeo.com/video/351181309?h=7f282a5c1b'
				}
				imageUrl={
					'https://images.squarespace-cdn.com/content/v1/5caff1f2a9ab95205a7638fe/1628183736618-M15OME1MAR8QXR10BE90/image-asset.png?format=1500w'
				}
			/>
		</Box>
	);
};

export default Composition;
