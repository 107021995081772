import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ViewportProvider } from './utils/ViewportProvider';
import { ThemeProvider } from '@mui/material';
import { theme } from './utils/Theme';

ReactDOM.render(
	<React.StrictMode>
		<ViewportProvider>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</ViewportProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
