import { IconButton, Typography, Link, Container, Box } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import React from 'react';

export const DevInfo = () => {
	return (
		<Container maxWidth="xl">
			<Box
				sx={{
					marginTop: '5vh',
					marginBottom: { xs: '4vh', md: '7.5vh' },
					display: 'flex',
				}}>
				<Typography sx={{ typography: { xs: 'h4', md: 'h2' } }}>
					Software Development
				</Typography>
				<Link
					href="https://github.com/ArtSze"
					target="_blank"
					underline="none"
					sx={{ marginLeft: '2vw', alignSelf: 'center' }}>
					<IconButton sx={{ borderRadius: '10px' }}>
						<GitHubIcon />
						<Typography variant="body1">artsze</Typography>
					</IconButton>
				</Link>
			</Box>

			<Box>
				<Box sx={{ marginBottom: { xs: '2vh', md: '5vh' } }}>
					<Typography sx={{ typography: { xs: 'h6', md: 'h5' } }}>
						Recent Projects
					</Typography>
				</Box>

				<Box sx={{ marginBottom: { xs: '2vh', md: '4vh' } }}>
					<Typography sx={{ typography: { xs: 'h6', md: 'h5' } }}>
						<Link
							href="https://github.com/ArtSze/rc-prjkt"
							target="_blank"
							underline="hover">
							RC Projects
						</Link>
					</Typography>

					<Typography
						sx={{ typography: { xs: 'body1', md: 'body2' } }}>
						{' '}
						A full-stack web application that provides a platform
						for members of The Recurse Center's community to post
						information about projects they've been working on.{' '}
					</Typography>
					<Typography variant="body2">
						<Link
							href="https://projects.recurse.com/"
							target="_blank"
							underline="hover">
							live link
						</Link>
					</Typography>
				</Box>
				<Box sx={{ marginBottom: { xs: '1vh', md: '4vh' } }}>
					<Typography sx={{ typography: { xs: 'h6', md: 'h5' } }}>
						<Link
							href="https://github.com/ArtSze/orbit"
							target="_blank"
							underline="hover">
							Orbit
						</Link>
					</Typography>

					<Typography
						sx={{ typography: { xs: 'body1', md: 'body2' } }}>
						{' '}
						A browser-based Euclidean sequencer. It allows for
						exploration of interesting rhythmic relationships
						uncommon in Western music (i.e. "4 against 5").{' '}
					</Typography>
					<Typography variant="body2">
						<Link
							href="https://orbit.recurse.com/"
							target="_blank"
							underline="hover">
							live link
						</Link>
					</Typography>
				</Box>
				<Box
					sx={{
						marginBottom: { xs: '1vh', md: '4vh' },
						marginTop: { xs: '3vh', md: '30vh' },
					}}>
					<Typography variant="body2">
						These two projects were born at the{` `}
						<Link
							href="https://recurse.com/"
							target="_blank"
							underline="hover">
							Recurse Center
						</Link>
						.
					</Typography>
				</Box>
			</Box>
		</Container>
	);
};
