import { useMemo } from 'react';
import * as Tone from 'tone';

enum PitchClass {
	A2 = 'A2',
	C3 = 'C3',
	D3 = 'D3',
	E3 = 'E3',
	G3 = 'G3',
	A3 = 'A3',
	C4 = 'C4',
	D4 = 'D4',
	E4 = 'E4',
	G4 = 'G4',
	A4 = 'A4',
	C5 = 'C5',
	D5 = 'D5',
}

export const pitches = Object.values(PitchClass);

class Voice {
	synth: Tone.Synth;
	destination: Tone.Channel;
	constructor(readonly pitch: PitchClass, destination: Tone.Channel) {
		this.destination = destination;
		this.synth = new Tone.Synth({
			volume: -9,
			oscillator: { type: 'square7' },
		}).connect(this.destination);
	}

	playNote() {
		this.synth.triggerAttackRelease(this.pitch, '5s', Tone.now());
	}
}

export default Voice;
