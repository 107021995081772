import { Typography, Link, Container, Box } from '@mui/material';
import React, { useState } from 'react';
import Composition from './soundInfoSubComponents/Composition';
import { Engineering } from './soundInfoSubComponents/Engineering';
import { SubHeadingSwitch } from './soundInfoSubComponents/SubHeadingSwitch';

export const SoundInfo = () => {
	const [showComposition, setShowComposition] = useState(true);

	return (
		<Container maxWidth="xl">
			<Box
				sx={{
					marginTop: '5vh',
					marginBottom: '4.5vh',
				}}>
				<Typography sx={{ typography: { xs: 'h4', md: 'h2' } }}>
					Music
				</Typography>
				<Typography variant="body2">(select work)</Typography>
			</Box>

			<Box marginBottom="3vh">
				<Typography
					sx={{
						typography: { xs: 'h6', md: 'h4' },
						marginBottom: { xs: '2vh', md: '2vh' },
					}}>{`Production & Engineering`}</Typography>
				<Engineering />
			</Box>
			<Box marginBottom="3vh">
				<Typography
					sx={{
						typography: { xs: 'h6', md: 'h4' },
						marginBottom: { xs: '2vh', md: '2vh' },
					}}>
					Composition
				</Typography>
				<Composition />
			</Box>
		</Container>
	);
};
