import {
	createRef,
	RefObject,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useViewport } from './hooks/useViewport';

import './styles.scss';

import VoiceContainer from './utils/voiceContainer';
import BackgroundSvg from './backgroundSubComponents/BackgroundSvg';
import { Name } from './backgroundSubComponents/Name';
import { DevInfo } from './DevInfo';
import { SoundInfo } from './SoundInfo';
import { Contact } from './Contact';
import { HelpIcon } from './backgroundSubComponents/HelpIcon';
import { NameAlt } from './NameAlt';

const App = () => {
	const nameContainerDivRef = useRef<HTMLDivElement>(null);
	const nameArr = 'arturszerejko'.split('');

	const [elRefs, setElRefs] = useState<RefObject<HTMLDivElement>[]>([]);

	useEffect(() => {
		// add or remove refs
		setElRefs((elRefs) =>
			Array(nameArr.length)
				.fill(null)
				.map((_, i) => elRefs[i] || createRef())
		);
	}, [nameArr.length]);

	const voiceContainer = useMemo(() => {
		return new VoiceContainer();
	}, []);

	let { width } = useViewport();

	useEffect(() => {
		console.log(width);
	}, [width]);

	return (
		<div className="snapContainer">
			{width < 600 ? (
				<NameAlt className="snapChild" id="mobileName" />
			) : (
				<div className="snapChild" ref={nameContainerDivRef}>
					<HelpIcon />
					<Name
						passedRef={nameContainerDivRef}
						elRefs={elRefs}
						nameArr={nameArr}
						voiceContainer={voiceContainer}
					/>
					<BackgroundSvg
						passedElRefs={elRefs}
						voiceContainer={voiceContainer}
					/>
				</div>
			)}

			<div className="snapChild" id="devInfo">
				<DevInfo />
			</div>
			<div className="snapChild" id="soundInfo">
				<SoundInfo />
			</div>
			<div className="snapChild" id="contact">
				<Contact />
			</div>
		</div>
	);
};

export default App;
