import * as Tone from 'tone';
import { Destination } from 'tone/build/esm/core/context/Destination';
import Voice, { pitches } from './voice';

class VoiceContainer {
	master: Destination;
	reverb: Tone.Reverb;
	reverbChannel: Tone.Channel;
	pingPong: Tone.PingPongDelay;
	pingPongChannel: Tone.Channel;
	subMaster: Tone.Channel;
	voiceChannels: Tone.Channel[];
	voices: Voice[];
	constructor() {
		this.master = Tone.getDestination();
		this.pingPong = new Tone.PingPongDelay(0.3, 0.9).toDestination();
		this.pingPongChannel = new Tone.Channel({ volume: -12 })
			.receive('delay')
			.connect(this.pingPong);
		this.reverb = new Tone.Reverb(2).toDestination();
		this.reverbChannel = new Tone.Channel({ volume: -1 })
			.receive('reverb')
			.connect(this.reverb);
		this.subMaster = new Tone.Channel({
			channelCount: 2,
			volume: -6,
		}).toDestination();
		this.voiceChannels = pitches.map(() => {
			return new Tone.Channel({ pan: 0 }).connect(this.subMaster);
		});
		this.voices = pitches.map((pitch, i) => {
			return new Voice(pitch, this.voiceChannels[i]);
		});
		this.voiceChannels.forEach((channel) => {
			channel.send('reverb', -4);
			channel.send('delay', -4);
		});
	}
}

export default VoiceContainer;
