import { Link, Box } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useViewport } from '../hooks/useViewport';

type CustomPopOverProps = {
	text: string;
	linkUrl: string;
	imageUrl: string;
	description?: string;
};

export const CustomPopOver = ({
	text,
	linkUrl,
	imageUrl,
	description,
}: CustomPopOverProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const { width } = useViewport();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: { xs: 'column', md: 'row' },
				marginBottom: '1vh',
				alignItems: { xs: 'flex-start', md: 'center' },
			}}>
			<Link href={linkUrl} target="_blank" underline="hover">
				<Typography
					sx={{
						typography: { xs: 'body1', md: 'h4' },
					}}
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}>
					{text}
				</Typography>
			</Link>

			{width > 600 ? (
				<Popover
					sx={{
						pointerEvents: 'none',
					}}
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus>
					<img
						style={{ display: 'block', width: '400px' }}
						src={imageUrl}
						alt={`${text}image`}></img>
				</Popover>
			) : null}

			{description ? (
				<Typography
					sx={{
						typography: { xs: 'body2', md: 'h6' },
						marginLeft: { xs: '4vw', md: '.5vw' },
					}}>
					{width > 600 ? '- ' : null}
					{description}
				</Typography>
			) : null}
		</Box>
	);
};
