import React, { RefObject, useEffect, useLayoutEffect, useRef } from 'react';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import ReactDOM from 'react-dom';
import * as Tone from 'tone';

import VoiceContainer from '../utils/voiceContainer';

const BackgroundSvg = ({
	passedElRefs,
	voiceContainer,
}: {
	passedElRefs: RefObject<HTMLDivElement>[];
	voiceContainer: VoiceContainer;
}) => {
	const pathArr = [
		'M300 15.78l-.29 47.99-.68 11.98-1.54 11.9-2.81 11.66-3.16 8.42-2.61 5.4-3 5.2-6.99 9.74-5.93 6.76-8.58 8.39-9.12 7.8-11.91 9.12-24.78 16.9-58.2 37.05-14.87 10.16-14.44 10.73-11.53 9.6-10.75 10.45-7.71 9.18-4.99 7.49-3.97 8.07-2.6 8.6-.78 5.95-.37 11.99-.8 8.96-1.81 11.87-2.53 11.72-5.75 20.2-9.06 25.43-6.72 16.69-8.34 19.28-7.5 16.36-9.16 18.89-9.62 18.67-8.63 15.8-9.02 15.57L0 521.08',
		'M301.59 17.88L300.9 15l-2.98.27-11.8 2.2-17.53 4.09-23.26 5.91-54.98 15.04-51.78 15.29-45.79 14.4-48.37 16.17-42.4 15.08M599.37 298.51l-3.59-4.81-4.95-7.52-5.7-10.55-4.53-11.1-3.28-11.54-1.67-8.84-1.29-11.93-.49-11.99-.16-24-.63-11.98-1.69-11.87-2.25-8.71-2.02-5.65-2.45-5.48-2.85-5.27-3.24-5.06-7.43-9.41-8.43-8.53-9.17-7.74-9.68-7.08-12.62-8.1-13.04-7.42-16.02-8.2-16.31-7.61-33.2-13.91-28.04-10.66-31.09-11.09-39.78-13.45-22.91-7.15-2.93-.63-5.78 1.09-1.38 2.55-.39 2.97',
		'M301.13 16.54l-.66 2.88-11.13 4.46-108.56 34.78-59.68 20.19-36.73 13.1-28.13 10.43L.39 124.29',
		'M599.45 297.84l-5.25-7.3-3.21-5.07-5.62-10.6-4.45-11.13-3.2-11.57-1.6-8.85-1.22-11.93-.45-11.99-.17-24-.69-11.98-1.78-11.86-2.33-8.69-3.28-8.38-2.71-5.35-3.1-5.13-7.22-9.58-8.27-8.69-6.73-5.97-7.06-5.58-7.32-5.23-10.09-6.5-10.35-6.06-10.57-5.69-24.31-11.74-24.81-10.65-27.93-10.96-47.97-17.29-59.64-20.3-2.8-1.09-2.16-2.02-2.38 1.73-.97 5.89-2.3 1.92-7.74 4.58-10.9 5-25.13 9.87-87.29 32.1-58.85 22.47-55.62 22.52-49.52 21.52',
		'M598.24 300.25l-3.66-4.75-5.08-7.43-5.9-10.45-4.75-11.01-2.75-8.57-1.44-5.82-1.6-8.85-1.23-11.94-.45-11.99-.17-24-.68-11.98-1.78-11.86-2.33-8.69-3.28-8.37-2.72-5.35-3.1-5.13-7.22-9.58-8.29-8.67-6.73-5.97-7.07-5.57-9.83-6.88-10.17-6.37-10.43-5.93-13.3-6.93-13.53-6.48-16.45-7.3-16.64-6.88-19.57-7.61-22.53-8.25-25.52-8.82-22.82-7.45-17.23-5.18-11.65-2.89-5.94-.74-2.97.36-2.57 1.49-3.64 4.73-1.03 2.81-.6 2.94-.63 5.96-.49 11.99-.28 24-.14 123',
		'M599.84 298.2l-5.28-7.29-3.23-5.06-5.66-10.57-4.5-11.12-3.24-11.55-1.64-8.84-1.26-11.93-.47-11.99-.16-24-.66-11.98-1.73-11.87-2.28-8.7-3.24-8.39-2.68-5.37-3.07-5.15-7.16-9.62-8.24-8.72-6.71-6-7.05-5.59-7.31-5.25-10.07-6.52-10.35-6.07-10.56-5.71-24.3-11.76-22.02-9.53-27.89-11.05-42.28-15.41-65.26-22.4-5.58-2.2-2.28-1.91-2.58 1.18-.51 2.93-.32 5.98-1.1 2.79-1.94 2.28-4.54 3.93-4.91 3.44-13.13 7.23-16.31 7.63-19.27 8.33-97.06 40.06-55.17 23.58-43.75 19.75-40.53 19.55',
		'M307.94 20.07l-2.9-.77-2.29-1.77-2.69-.42-.09 15-.5 2.96-1.09 2.79-1.56 2.57-5.79 6.87-4.45 4.02-9.72 7.04-10.22 6.27-18.44 10.05-21.46 10.75-108.52 51.21-40.44 19.74-42.59 22.13-18.35 10.21-15.53 9.11',
		'M300.01 16.76l-.19 20.99-1.05 5.9-2.3 5.53-4.85 7.58-5.94 6.74-6.68 6.03-9.54 7.28-17.57 11.49-28.63 16.4-26.49 14.1-87.9 45.53-42.07 23.12-18.09 10.66-17.78 11.17-14.89 10.12-14.42 10.77',
		'M300 16.45l-.2 27-1.1 8.92-1.62 5.77-5.13 10.84-5.03 7.45-7.96 8.98-8.84 8.11-9.38 7.48-9.72 7.03-9.96 6.7-15.22 9.6-31.04 18.23-75.76 42.78-23.19 13.82-15.23 9.6-17.39 11.76-14.44 10.75-11.52 9.6-10.83 10.38-7.91 9.01-5.3 7.28-4.53 7.77-3.51 8.27-1.63 5.78-.94 5.92-.78 20.98-1.46 14.93',
		'M299.87 46.2l-.55 8.98-1.45 8.88-2.68 8.58-3.68 8.21-4.57 7.75-5.37 7.21-8.09 8.86-8.81 8.15-9.28 7.6-9.61 7.18-22.38 15.1-25.52 15.77-67.02 39.91-17.75 11.21-14.95 10.03-14.55 10.59-13.95 11.38-10.89 10.31-6.05 6.65-3.77 4.67-5.11 7.41-2.95 5.22-2.48 5.46-1.92 5.68-1.64 8.84-.64 17.98-1.51 14.93-1.88 11.85-3.07 14.68-4.53 17.42-6.18 20.06L9.1 415.4.41 437.77',
		'M299.43 60.73l-1.47 11.9-2.14 8.74-3.19 8.41-3.98 8.07-4.72 7.65-5.43 7.18-5.97 6.73-8.6 8.37-6.8 5.9-9.4 7.45-9.68 7.09-12.37 8.48-22.73 14.57-56.16 34.67-15.08 9.83-14.78 10.27-14.32 10.91-11.37 9.78-10.56 10.64-7.53 9.33-3.33 5-2.93 5.23-2.44 5.48-1.86 5.7-1.47 8.86-.33 12-.66 8.97-2.55 17.82-1.8 8.81-2.86 11.66-6.9 22.98-9.11 25.41-11.27 27.81-12.27 27.37L.09 486.7',
		'M300 40.88l-.13 24-.45 14.99-.87 11.97-1.15 8.92-1.77 8.82-3.27 11.55-3.31 8.36-4.15 7.98-4.95 7.52-5.62 7.02-8.33 8.64-8.99 7.94-9.44 7.4-12.21 8.71L180 220.64l-12.4 8.44-12.12 8.83-11.68 9.41-8.84 8.11-8.15 8.8-5.44 7.17-3.17 5.09-2.68 5.36-2.88 8.52-.96 5.92-.44 11.99-1.29 11.92-2.24 11.79-3.02 11.61-3.61 11.45-4.05 11.29-11.4 27.75-8.68 19.12-10.44 21.61-10.94 21.36-11.48 21.07-10.55 18.16-11.06 17.85-11.63 17.49L.42 545.42',
		'M300.01 14.47l-.04 33-.36 14.99-.97 11.96-1.46 8.88-1.44 5.82-1.86 5.71-3.59 8.24-4.5 7.8-7.15 9.62-7.97 8.97-8.64 8.32-11.49 9.64-12.01 8.99-24.87 16.77-48.31 30.25-17.64 11.39-14.86 10.16-14.44 10.75-11.52 9.59-8.71 8.26-8.02 8.92-6.98 9.75-4.21 7.94-2.97 8.49-1.39 8.88-.34 11.99-.75 8.97-2.79 17.77-2.67 11.7-3.17 11.58-7.47 22.8-6.31 16.86-5.61 13.91-13.22 30.23-15.74 32.38-15.73 29',
		'M299.78 65.45l-.5 11.99-1.1 11.95-2.11 11.81-2.48 8.65-3.36 8.34-4.26 7.92-6.82 9.87-7.71 9.18-6.34 6.4-6.68 6.02-9.31 7.57-9.64 7.16-22.35 15.13-50.47 32.44-14.79 10.26-11.98 9.02-11.48 9.66-8.61 8.35-7.84 9.08-5.12 7.4-4.16 7.97-2.84 8.53-.96 5.92-.42 11.99L97.29 320l-2.05 11.82-2.78 11.67-6.19 20.06-9.56 25.25-8.21 19.32-8.7 19.12-9.12 18.92-9.53 18.71-9.99 18.47-8.96 15.61-9.36 15.38-11.45 17.6',
		'M299.96 52.23l-.41 17.99-.87 11.97-1.77 11.86-2.18 8.73-3.05 8.46-3.97 8.08-4.79 7.61-7.46 9.39-6.2 6.53-8.84 8.11-9.31 7.57-9.62 7.17-22.34 15.15-55.59 35.58-14.81 10.23-12.01 8.98-11.52 9.6-8.68 8.29-7.94 8.99-6.95 9.78-3 5.19-2.49 5.46-2.56 8.61-.77 5.95-.38 11.99-.83 8.96-1.88 11.85-2.6 11.71-5.91 20.15-9.27 25.36-8.02 19.4-8.53 19.19-8.97 18.99-8.03 16.11-8.36 15.94-10.21 18.35-9.18 15.48-9.61 15.22',
		'M298.44 78.68l-1.52 8.87-2.34 8.68-2.07 5.63-3.89 8.11-4.75 7.65-5.45 7.15-6 6.7-6.43 6.31-13.72 11.63-19.4 14.13-20.04 13.2L157.04 212l-14.9 10.09-12.14 8.82-11.73 9.35-11.09 10.09-8.16 8.79-7.24 9.57-3.24 5.05-2.81 5.29-3.2 8.4-1.64 8.84-.38 11.99-1.05 11.95-1.32 8.9-1.73 8.84-2.82 11.66-3.33 11.53-8.79 25.52-12.35 30.6-8.51 19.2-7.65 16.29-14.92 29.43-8.67 15.78L.3 513.52M308.56 19.98l-2.82-1.03-2.36-1.8-2.89-.03-.31-5.99-2.96-.4-8.99.36-8.92 1.2-14.76 2.66L244 19.27l-29.22 6.79-29.11 7.25-29.02 7.62-34.7 9.58-63.28 18.74L1.55 87.64',
		'M303.08 19.4l-1.6-2.43-75.03 21.3-57.42 17.39-42.85 13.74L80.7 84.77 41.14 98.88 1.83 113.67M300.04 12.74l-5.98.22-5.94.87-14.72 2.86-14.64 3.27-40.79 10.03-49.25 13.23-43.25 12.42-40.18 12.22L39.6 82.58 2.69 95.17',
		'M301.31 18.5l-.54 2.92-2.33 1.88-5.34 2.72-8.33 3.41-22.58 8.14-87.95 30.22-56.46 20.29-61.64 23.58-27.82 11.25L.67 134.55M298.22 28.88l-4.75 3.64-5.19 3.01-10.78 5.26-11 4.8-33.39 13.45-83.85 32.72-50.05 20.27-55.15 23.62L.45 155.7',
		'M301.7 18.29l-.74 2.84-1.48 2.59-5.08 3.17-10.97 4.86-25.28 9.47-90.47 32.11-53.45 19.82-27.98 10.81-33.42 13.39L.38 139.66M598.33 307.99l-9.71-11.43-8.56-12.3-5.75-10.53-4.61-11.08-2.65-8.59-1.38-5.84-1.89-11.85-1.04-14.96-.58-35.99-1.28-11.93-1.84-8.8-2.78-8.56-2.4-5.49-2.82-5.3-3.21-5.06-3.56-4.83-7.99-8.95-6.59-6.13-6.96-5.7-9.74-7.01-10.11-6.46-10.39-6-10.6-5.62L468.41 83l-13.67-6.18-16.59-6.98-19.55-7.66-36.72-13.13-19.96-6.53-20.11-6.04-14.53-3.73-8.85-1.62-5.98-.34-2.98.36-2.77 1.1-2.18 2.04-1.38 2.66-.84 2.87-.9 5.93-.71 11.98-.38 21-.24 126',
		'M599.69 325.55l-8.9-8.05-6.35-6.38-6.02-6.69-5.63-7.02-5.16-7.37-4.62-7.72-4.02-8.05-3.35-8.35-1.85-5.71-2.2-8.72-1.54-8.87-.69-5.96-.82-14.97-.34-30-.64-8.97-1.28-8.91-2.13-8.74-3.09-8.45-4.05-8.03-3.2-5.07-5.45-7.16-8.23-8.73-9.05-7.87-12.11-8.84-12.73-7.93-13.16-7.2-16.18-7.87-19.28-8.33-22.41-8.58-22.75-7.66-20.16-5.84-17.54-4.06-8.88-1.46-5.97-.61h-8.99l-5.89 1.06-2.82 1.02-2.64 1.42-2.4 1.8-2.1 2.14-1.79 2.4-1.51 2.59-2.35 5.52-1.68 5.75-1.23 5.87-2.04 14.86-1.35 17.95-.8 20.98-.54 30-.22 39M300 27.17l-.24 54-.49 14.99-1.14 14.96-1.78 11.86-2.8 11.67-2.9 8.51-3.8 8.15-4.73 7.66-5.54 7.08-6.19 6.53-8.96 7.97-9.5 7.33-9.82 6.9-50.44 32.49-19.63 13.8-9.42 7.44-8.97 7.97-8.28 8.67-5.53 7.1-3.21 5.06-2.72 5.35-2.85 8.52-.89 5.93-.77 14.97-1.3 8.91-2.63 11.7-3.44 11.5-4.03 11.3-5.62 13.91-13.69 30.02-12.02 24.18-12.57 23.89-11.67 20.97-12.21 20.66-11.19 17.77-11.72 17.43-14.08 19.43L.3 560.29',
		'M299.83 90.17l-.97 29.98-.75 8.97-1.2 8.92-2.61 11.71-1.74 5.74-3.46 8.3-2.94 5.23-3.41 4.93-5.88 6.8-4.32 4.17-6.88 5.8-7.21 5.38-9.93 6.74-40.73 25.4-12.54 8.23-9.8 6.91-9.5 7.34-9.03 7.9-6.31 6.41-5.73 6.93-3.37 4.97-2.88 5.26-3.09 8.44-1.02 5.9-.85 14.97-1.43 8.89-2.9 11.64-3.77 11.39-4.36 11.18-7.25 16.47-7.78 16.23-9.49 18.74-14.07 26.49-13.17 23.57-13.75 23.24-11.17 17.78-11.65 17.47-13.97 19.51-14.69 18.98L.79 569.21',
		'M299.86 105.21l-.71 29.99-.58 8.98-.99 8.95-1.74 8.82-2.61 8.61-2.36 5.51-3.02 5.19-3.62 4.77-4.12 4.37-4.47 3.99-7.15 5.47-15.09 9.8-43.77 26.18-12.48 8.31-7.28 5.3-7.05 5.59-6.74 5.96-6.29 6.44-5.64 7-4.67 7.68-2.36 5.52-.88 2.86-1.1 5.9-.6 11.98-1.3 8.9-2.9 11.64-3.87 11.35-4.52 11.12-7.53 16.35-8.05 16.09-12.6 23.88L97.8 436.6l-13.54 23.35-14.1 23.03-13.11 20.1-15.51 22.1-12.68 16.74-13.22 16.31L1.9 574.11',
		'M299.88 123.32l-.59 29.99-1.26 14.94-1.98 8.77-2.08 5.63-2.73 5.33-3.58 4.81-4.24 4.24-4.66 3.78-4.92 3.44-12.81 7.79-41.8 23.6-12.69 7.98-9.86 6.85-7.12 5.5-6.81 5.88-6.35 6.37-5.68 6.97-4.69 7.68-2.32 5.52-.86 2.88-1.01 5.91-.65 11.97-1.48 8.88-3.18 11.56-4.14 11.26-6.02 13.74-7.93 16.16-11.18 21.23-13.01 23.66-16.38 28.65-13.88 23.16-12.81 20.29-13.38 19.93-14.02 19.48-12.83 16.62-15.32 18.47-13.95 15.69',
		'M299.8 159.59l-.46 15-1.04 8.93-1.83 5.7-2.92 5.24-3.8 4.62-4.6 3.85-7.54 4.9-47.65 25.39-12.97 7.55-10.11 6.45-9.78 6.95-9.28 7.61-8.48 8.48-5.53 7.09-4.4 7.84-2.03 5.64-1.11 5.89-.67 11.97-1.52 8.87-3.27 11.54-4.27 11.21-6.17 13.67-8.1 16.08-11.36 21.14-16.13 28.79-15.08 25.93-15.61 25.62-13 20.17-13.58 19.79-12.4 16.94-14.83 18.88-15.51 18.31L.66 581.18',
		'M300 67.33l-.06 117-.37 8.99-.83 2.88-1.62 2.51-4.22 4.25-2.38 1.83-5.12 3.13-48.38 23.97-13.15 7.22-12.81 7.8-9.89 6.79-9.41 7.45-6.58 6.13-5.97 6.73-5.05 7.44-1.4 2.65-2.23 5.57-1.33 5.84-.68 11.97-1.44 8.88-3.25 11.55-5.47 13.96-6.37 13.58-9.67 18.64-13.03 23.65-16.35 28.66-13.73 23.25-14.17 22.98-13.08 20.12-13.66 19.74-14.29 19.27-13.08 16.43-15.58 18.25-14.17 15.51M598.68 288l-6.18-10.28-5.09-10.86-3.02-8.48-2.94-11.63-1.43-8.88-1.03-11.95-.46-33-.65-11.98-1.17-8.92-1.98-8.77-1.83-5.71-2.26-5.56-4.19-7.96-3.27-5.03-3.6-4.8-8.04-8.9-11.21-9.96-12.07-8.89-17.83-11.09-18.51-9.92-21.66-10.32-27.56-11.86-27.87-11.11-58.82-22.56-22.14-9.26-10.75-5.31-7.63-4.76-4.58-3.87-3.74-4.68',
		'M599.57 292.79l-6.4-10.14-5.33-10.74-4.11-11.27-2.87-11.65-1.38-8.89-.98-11.96-.44-32.99-.44-8.99-1.49-11.9-2.05-8.76-1.88-5.7-2.3-5.54-4.23-7.93-3.29-5.02-3.62-4.79-8.04-8.9-11.19-9.97-7.16-5.46-7.39-5.13-15.32-9.44-18.51-9.93L464 74.95l-27.67-11.59-33.59-12.94-61.96-22.74-16.7-6.72-10.72-5.38-5.01-3.29-2.21-2.03-1.83-2.37-1.34-2.68-.89-2.86M299.64 32.07l-1.27 2.72-1.79 2.4-2.09 2.16-4.63 3.81-12.64 8.07-15.86 8.49-32.57 15.33-98.81 43.61-46.4 21.16-45.93 22.17L.42 181.41',
		'M299.43 31.36l-1.58 2.55-2.01 2.23-7.08 5.52-10.33 6.1-16.1 8.07-30.1 13.52-88.3 37.67-52.19 22.91-48.98 22.72L2.5 172.76',
		'M299.01 29.14l-2.05 2.19-2.33 1.88-5.03 3.26-7.93 4.26-13.59 6.34-27.66 11.62-97.47 39.06-44.32 18.42-52.21 22.88-21.79 10.05L.3 160.8M299.65 37.46l-.65 2.93-2.45 5.46-3.56 4.82-4.18 4.29-6.92 5.76-7.3 5.25-7.51 4.98-10.25 6.22-18.35 10.22-21.29 11.07-102.18 50.55-40.02 20.58-23.73 12.87-18.24 10.42-15.41 9.3-15.14 9.73',
		'M299.59 33.98l-1.08 2.8-3.48 4.87-6.72 5.98-9.94 6.7-18.35 10.2-16.11 8.04-21.7 10.26-90.09 41.04-48.87 22.95-45.64 22.77-18.55 9.85L.72 189.66',
		'M299.56 36.88l-.8 2.89-2.83 5.28-1.83 2.37-4.17 4.31-4.57 3.89-7.27 5.3-7.57 4.87-10.34 6.09-18.47 9.98-21.42 10.82-97.27 46.94-48.29 24.17-21.24 11.17-21.02 11.58-18.14 10.57-12.77 7.87',
		'M299.64 42.91l-.99 5.91-1.93 5.68-2.81 5.29-3.48 4.89-6.05 6.66-6.56 6.16-6.92 5.75-9.64 7.14-17.55 11.53-23.22 13.77-26.22 14.57-81.84 44.18-23.5 13.29-23.17 13.86-20.15 13.04-17.08 12.21-16.26 13.28-10.82 10.39',
		'M299.56 39.65l-.61 2.93-2.23 5.56-3.29 5.01-3.97 4.5-4.36 4.11-7.01 5.65-7.35 5.2-10.09 6.48-18.15 10.57-21.12 11.4-109.67 55.68-37.12 19.65-20.94 11.72L33 200.35l-17.7 11.29L.51 221.91',
		'M298.24 48.85l-2.31 5.53-3.16 5.1-3.76 4.67-4.16 4.32-4.44 4.04-7 5.65-7.28 5.29-9.99 6.65-17.93 10.92-20.9 11.81-103.33 54.87-23.66 13.01-20.81 11.96-23.01 14.12-17.45 11.68-14.48 10.68L.75 240.68',
		'M299.69 48.67l-1.06 8.93-1.39 5.84-2.03 5.64-4.17 7.97-5.23 7.32-5.96 6.73-8.67 8.3-9.14 7.78-9.53 7.28-9.8 6.92-22.68 14.66-23.15 13.89-70.07 40.62-17.95 10.91-17.67 11.35-17.23 11.99-14.24 11.01-11.27 9.89-10.44 10.77-5.67 6.98-5.03 7.46-4.17 7.97-3 8.48-1.54 8.85-.44 14.99-.91 11.97-1.5 11.9-1.48 8.88-2.38 11.76-3.52 14.58L.8 396.2',
		'M299.65 45.01l-.83 5.94-1.6 5.78-2.41 5.49-3.09 5.14-3.62 4.78-6.1 6.61-6.64 6.08-9.37 7.49-17.23 11.99-22.97 14.2-28.61 16.44-86.6 47.96-25.85 15.24-22.78 14.48-12.35 8.52-9.65 7.13-9.38 7.48-6.8 5.9-8.65 8.31-6.07 6.64-5.58 7.06-3.37 4.96',
		'M299.66 47.85l-1.18 8.91-1.56 5.8-2.24 5.56-4.49 7.79-5.49 7.12-6.17 6.55-6.63 6.09-9.3 7.58-17.08 12.21-22.77 14.5-28.44 16.75-70.32 40.2-20.55 12.39-15.18 9.67-17.32 11.87-16.7 12.73-11.4 9.74-10.64 10.57-7.68 9.21-5.05 7.45-4.18 7.96-3.05 8.46-1.6 8.85-.79 20.98-1.94 17.89-3.1 17.73-4.78 20.44',
		'M298.4 53.72l-1.8 5.72-2.56 5.42-3.19 5.08-5.65 7-6.34 6.39-6.77 5.92-7.07 5.57-9.74 7.01-17.58 11.48-23.16 13.87-86.45 48.25-31.13 18.08-27.91 17.6-12.35 8.52-9.64 7.13-9.37 7.5-6.79 5.9-6.52 6.21-6.18 6.54-5.71 6.96-5.16 7.37-4.55 7.75-2.5 5.46',
		'M299.8 51.5l-.89 11.96-1.6 8.85-2.67 8.59-3.79 8.15-4.76 7.63-5.53 7.1-6.09 6.63-10.94 10.25-6.89 5.8-9.49 7.34-22.18 15.39-25.38 15.99-59.01 35.76-17.69 11.32-14.88 10.13-16.81 12.58-11.44 9.69-10.68 10.53-7.7 9.19-5.04 7.46-2.88 5.26-2.39 5.5-1.81 5.72-1.12 5.89-.97 20.96-2.16 17.87-1.6 8.86-2.59 11.71-6.41 23.13-6.61 19.93-8.37 22.49-9.07 22.22-9.72 21.94',
		'M299.36 54.46l-1.42 8.88-1.64 5.77-2.23 5.57-4.35 7.87-5.3 7.27-5.98 6.72-6.45 6.27-9.12 7.8-9.51 7.31-9.79 6.95-22.62 14.73-23.11 13.97-67.26 39.49-17.85 11.07L97.68 214l-17.13 12.14-16.47 13.02-11.06 10.13-10.09 11.09-5.35 7.23-3.17 5.1-3.95 8.08-1.97 5.66-1.71 8.82-.65 17.99-1.05 11.95-2.15 14.84-2.3 11.78-3.48 14.59-4.88 17.33-5.48 17.14-5.97 16.98',
		'M299.69 52.95l-.81 8.97-1.72 8.83-1.77 5.72-3.65 8.22-4.69 7.68-5.48 7.13-6.08 6.64-8.73 8.22-6.9 5.79-9.5 7.32-22.23 15.32-25.43 15.91-66.87 40.16-17.7 11.3-14.88 10.13-14.45 10.73-13.77 11.58-10.64 10.57-5.94 6.76-3.7 4.72-4.99 7.49-2.84 5.28-2.34 5.52-1.76 5.73-1.07 5.9-.93 20.98-2.12 17.87-3.43 17.66-5.26 20.33-5.32 17.19-6.9 19.84-8.61 22.4-8.09 19.38',
		'M61.88 241.42l-10.69 10.52-7.71 9.19-3.44 4.91-3.1 5.14-2.65 5.38-2.13 5.61-1.48 5.8-.78 5.95-.44 14.99-1.34 14.94-1.75 11.87-2.91 14.71-4.35 17.47-5.08 17.26-6.64 19.93L.14 424.8M299.55 61.88l-.75 8.97-1.42 8.88-2.3 8.7-3.29 8.37-4.25 7.93-5.07 7.44-5.71 6.95-8.36 8.6-6.68 6.03-9.29 7.6-19.42 14.09-22.6 14.76-61.1 38.09-14.98 9.98-12.23 8.69-14.19 11.07-11.19 9.99-10.34 10.85-7.4 9.44-3.23 5.06-2.8 5.3-2.29 5.55-1.66 5.76-.94 5.92-.37 11.99-.63 8.98-2.55 17.81-1.82 8.81-2.9 11.65-4.23 14.39-3.77 11.39-10.48 28.11-12.85 30.39-12.7 27.18L.61 495.91',
		'M297.72 72.85l-1.43 5.82-3.05 8.46-2.62 5.4-4.71 7.66-3.57 4.82-5.86 6.83-6.33 6.4-8.95 7.98-14.18 11.09-19.73 13.66-22.8 14.47-58.88 35.97-17.64 11.4-14.81 10.23-14.35 10.86-13.61 11.77-8.41 8.55-9.53 11.58-4.91 7.53-2.77 5.32-2.25 5.56-1.64 5.77-.92 5.93-.94 20.97-2.38 17.84-1.71 8.83L47 355.23l-5.77 20.19-7.72 22.73-10.83 27.97-9.42 22.07L.58 475.38',
		'M299.91 67.01l-.37 18-.87 14.97-1.4 11.92-2.44 11.74-2.77 8.56-3.72 8.19-4.63 7.71-7.26 9.55-6.07 6.64-6.53 6.19-6.88 5.8-9.54 7.28-17.3 11.9-47.88 30.93-12.3 8.58-11.94 9.07-9.16 7.76-8.59 8.37-5.91 6.79-5.22 7.32-4.25 7.93-2.87 8.51-.94 5.92-.45 11.99-.97 8.95-2.19 11.79-3.04 11.61-3.68 11.42-5.25 14.05-5.74 13.86-7.34 16.43-10.31 21.67-12.19 24.1-11.34 21.15-10.37 18.26-10.84 17.99-11.35 17.66-11.92 17.29L1.6 551.74',
		'M299.73 70.5L299 85.48l-.9 8.96-2.05 11.82-2.41 8.67-3.28 8.37-4.18 7.97-5.01 7.47-7.7 9.2-6.35 6.37-6.71 5.99-9.36 7.52-9.66 7.11-17.38 11.78-52.92 34.18-14.73 10.35-9.54 7.28-11.44 9.7-8.55 8.41-7.77 9.14-5.22 7.33-2.98 5.2-2.47 5.47-2.47 8.64-.66 5.96-.42 11.99-.96 8.94-2.09 11.82-2.85 11.65-3.45 11.49-3.91 11.35-9.92 25.11-8.43 19.23-8.89 19.03-10.65 21.5-11.2 21.23-10.28 18.31-9.22 15.46-9.63 15.21-11.77 17.39M302.46 17.99l-2.18-1.73-.2-9-2.77-1.11-2.97-.4-5.97-.5-8.99.45-14.88 1.85-17.75 2.97-23.56 4.56-29.34 6.3-29.22 6.76-34.96 8.6-60.88 16.19-40.38 11.56-25.86 7.77',
		'M598.98 297.26l-3.53-4.85-4.86-7.57-5.55-10.63-4.37-11.17-3.12-11.58-1.94-11.84-.78-8.97-.41-11.99-.18-24-.47-8.98-1.54-11.9-2.1-8.75-1.92-5.68-2.34-5.52-4.29-7.91-6.97-9.75-8.1-8.85-11.25-9.91-7.18-5.43-7.42-5.1-17.98-10.84-21.31-11.03-13.59-6.34-16.51-7.18-16.66-6.81-22.39-8.63-36.68-13.25-70.92-24.38-1.9-2.26-1-2.81-.55-2.94-1.96-2.21-2.93-.58-3-.13-5.99.29-8.94 1.04-11.85 1.88-17.69 3.32-20.55 4.31-40.92 9.46-49.45 12.49L91.58 52.9 45.5 66.33 2.52 79.66',
		'M300.72 15.86l-8.81 1.8-14.57 3.6-40.56 10.86-43.27 12.36-43.08 13.01-34.32 10.88-42.7 14.21-33.98 11.88-39.41 14.5M297.66 27.86l-5.01 3.29-5.32 2.78-16.47 7.24-27.93 10.94-84.2 31.81-53.05 20.84-55.4 23.04L.99 149.85M598.89 315.71l-12.38-13.06-5.68-6.98-5.23-7.32-4.72-7.66-2.81-5.3-2.54-5.44-3.24-8.39-2.55-8.63-1.31-5.85-1.03-5.91-1.05-8.94-.65-11.98-.45-32.99-.72-8.97-.85-5.94-1.97-8.78-2.92-8.51-3.9-8.1-4.8-7.61-7.58-9.29-6.36-6.37-4.49-3.97-11.89-9.15-12.56-8.19-15.67-8.85-18.82-9.31-19.22-8.46-22.3-8.88-22.56-8.17-22.8-7.51-20.14-5.92-17.49-4.25-11.85-1.88-5.99-.27-2.99.2-2.95.55-2.8 1.05-2.49 1.66-2 2.22-1.48 2.61-1.08 2.8-1.38 5.83-1.12 8.93-.73 11.97-.79 38.99-.26 87',
		'M598.78 331.3l-9.25-7.63-8.89-8.07-8.39-8.57-5.9-6.8-7.21-9.58-4.84-7.59-4.26-7.92-3.64-8.24-2.96-8.49-1.58-5.79-1.83-8.81-1.21-8.92-.91-14.96-.45-33-.75-8.96-.88-5.94-2.02-8.77-1.89-5.69-2.33-5.53-4.31-7.89-5.18-7.36-7.99-8.94-6.61-6.1-9.42-7.43-9.93-6.74-12.9-7.65-13.29-6.94-16.33-7.57-22.23-9.04-22.63-7.99-22.99-6.88-11.64-2.91-8.81-1.84-8.89-1.43-5.96-.61-6-.22-5.98.38-5.85 1.3-2.78 1.13-2.59 1.51-2.33 1.88-2.01 2.22-1.69 2.48-1.4 2.65-2.11 5.61-2.03 8.76-1.58 11.9-1.27 17.95-.75 20.99-.7 68.99',
		'M599.73 329.97l-9.12-7.79-6.56-6.16-8.29-8.68-5.79-6.89-5.35-7.23-4.85-7.58-4.28-7.92-3.63-8.23-2.04-5.64-2.48-8.65-1.8-8.81-1.31-8.91-1.13-14.95-.56-35.99-.77-8.97-1.48-8.87-2.37-8.68-3.36-8.34-4.33-7.89-5.19-7.34-8-8.94-4.36-4.12-6.88-5.8-7.21-5.38-7.47-5.02-15.5-9.14-18.74-9.47-21.99-9.62-22.41-8.58-22.74-7.66',
		'M598.46 318.67l-6.42-6.3-6.1-6.62-5.72-6.94-5.28-7.3-3.23-5.05-4.36-7.87-2.56-5.43-3.27-8.38-1.79-5.73-2.12-8.74-2.1-14.85-.67-11.97-.44-33-.7-8.97-.84-5.94-1.94-8.79-2.88-8.52-3.85-8.13-4.74-7.64-7.5-9.36-8.51-8.45-11.64-9.45-12.4-8.43-15.54-9.08-16.03-8.19-19.13-8.67-22.24-9-22.57-8.17-22.83-7.38-20.24-5.61-14.65-3.21-14.87-1.92-8.99.12-5.82 1.39-2.68 1.34-2.38 1.82-1.97 2.25-1.57 2.55-2.18 5.58-1.86 8.8-1.49 14.92-.82 17.98-.5 24-.4 78',
		'M599.75 332.1l-9.22-7.68-8.82-8.14-8.29-8.67-5.8-6.88-5.37-7.22-4.86-7.57-4.29-7.91-3.65-8.22-2.06-5.64-2.5-8.64-1.83-8.81-.87-5.94-1.15-14.95-.55-35.99-.75-8.97-.88-5.93-2.02-8.77-1.88-5.7-2.31-5.53-4.28-7.91-5.13-7.39-7.92-9.01-8.84-8.11-9.5-7.32-9.97-6.67-12.94-7.58-13.33-6.89-16.35-7.52-19.45-7.9-22.63-8.01-20.1-6.07-20.42-4.87-11.85-1.88-5.98-.52-5.99-.08-5.97.59-2.93.64-5.56 2.21-2.53 1.62-4.29 4.17-3.48 4.88-2.72 5.34-2.68 8.58-2.12 11.81-1.71 17.91-1.02 20.98-.64 26.99-.31 39M300 29.86l-.23 60-.53 17.99-.79 11.98-1.44 11.91-1.8 8.81-2.68 8.59-3.76 8.17-4.83 7.58-5.75 6.93-8.47 8.49-6.85 5.83-7.16 5.45-9.85 6.86-48.06 30.63-19.69 13.73-9.42 7.43-8.95 7.99-6.25 6.47-5.66 6.99-3.32 5-2.85 5.28-3.06 8.45-1.03 5.9-.52 11.99-1.6 11.88-2.72 11.69-3.57 11.45-4.17 11.25-5.79 13.84-7.5 16.36-9.21 18.87-12.36 24.01-14.34 26.35-13.52 23.37-11 17.89-13.17 20.06L26 532.49l-12.66 16.75L.12 565.56',
		'M299.95 81.91l-.59 38.99-.64 11.98-.84 8.96-1.39 8.89-1.39 5.84-3.05 8.45-2.78 5.32-3.36 4.97-3.84 4.61-4.21 4.27-6.81 5.88-7.2 5.4-9.93 6.73-43.37 26.84-19.87 13.45-9.51 7.32-9.01 7.91-6.28 6.45-5.63 7.01-4.69 7.68-3.47 8.3-.9 2.86-1.15 5.88-.57 11.98-1.19 8.92-2.71 11.68-3.68 11.42-5.52 13.95-6.15 13.68-7.87 16.19-10.98 21.33-15.71 29.03-13.35 23.46-12.34 20.59-12.87 20.25-13.5 19.84-12.38 16.97-14.82 18.87-15.53 18.3',
		'M301.95 206.41l-2.88.58-11.31 4.01-22.17 9.2-21.75 10.12-18.57 9.8-15.37 9.37-12.17 8.75-9.06 7.87-4.18 4.3-3.86 4.59-4.94 7.51-2.5 5.46-.94 2.84-1.14 5.89-.71 11.97-1.61 8.85-3.45 11.49-4.44 11.14-6.38 13.58-9.71 18.61-13.09 23.62-14.91 26.03-16.82 28.39-14.23 22.95-14.82 22.57-12.02 17.21-14.36 19.24-15.03 18.7-13.72 15.9-14.2 15.47M599.78 280.34l-5.39-10.72-3.26-8.38-2.56-8.63-1.86-8.8-1.23-8.92-.7-8.97-.46-32.99-.4-8.99-1.41-11.91-1.97-8.78-2.9-8.52-2.47-5.46-2.88-5.26-3.26-5.04-3.59-4.8-8.02-8.93-11.18-9.99-7.14-5.47-7.39-5.14-15.3-9.47-18.47-9.99-21.62-10.42-24.73-10.84-27.76-11.36-53.01-20.97-24.83-10.6-10.78-5.25-10.39-6.01-7.2-5.38-4.24-4.24M299.63 48.31l-1.24 8.91-1.61 5.78-2.28 5.54-4.54 7.76-5.53 7.1-6.19 6.53L271.6 96l-9.32 7.56-9.67 7.1-9.91 6.76-22.85 14.38-23.27 13.69-72.92 41.71-17.99 10.83-17.72 11.26-17.31 11.89-14.32 10.9-11.37 9.78-10.58 10.63-7.58 9.29-4.93 7.53-4 8.05-2.01 5.66-1.78 8.8-1.06 23.97-1.26 11.94-1.31 8.9-4.07 20.6-6.04 23.22',
		'M300 27.48l-.09 12-.43 5.98-1.12 5.89-.89 2.86-2.42 5.49-3.16 5.1-5.68 6.96-6.42 6.31-9.22 7.67-9.69 7.09-9.96 6.68-25.62 15.6-26.14 14.73-89.53 48.87-23.36 13.53-20.43 12.59-19.93 13.37-9.68 7.09-9.42 7.44-13.41 12-6.25 6.47-5.87 6.83',
		'M298.68 54.03l-1.53 5.8-2.27 5.55-2.92 5.24-5.31 7.25-6.09 6.63-6.59 6.13-6.93 5.74-9.59 7.2-17.41 11.75-23.02 14.11-23.38 13.5-65.37 36.77-20.71 12.12-17.9 10.98-17.56 11.51-17.05 12.26-11.68 9.4L32.3 246.1l-8.18 8.77-7.28 9.54-3.17 5.09-2.75 5.33-3.17 8.41-1.73 8.82-.62 17.99-1.28 14.94-3.63 23.72',
		'M299.27 53.63l-1.66 8.84-1.88 5.7-2.49 5.45-4.73 7.65-5.61 7.03-6.22 6.5-6.65 6.07-9.3 7.58-9.65 7.13-9.89 6.8-20.23 12.9-20.59 12.34-72.67 42.12-17.93 10.94-15.14 9.72-19.69 13.72-14.17 11.1-11.17 10.01-10.27 10.92-5.5 7.12-4.78 7.62-3.81 8.15-1.83 5.71-1.48 8.86-.43 15-.95 11.96-1.54 11.9-2.07 11.82-3.21 14.65-3.79 14.51-4.25 14.39L.1 404.95M353.15 34.94L313.4 21.37l-5.62-2.09-2.67-1.36-2.75.67-1.06-2.72-.49-2.95-.51-8.98-2.12-2.09-2.82-1.03',
		'M300.52 15.17l-.36-8.99-.4-2.97-2.51-1.62-2.89-.8-8.96-.76',
		'M300.71 11.88l-.35-5.99-.92-2.84-2.63-1.43-5.89-1.05-8.99-.05-11.97.82-14.9 1.78-17.79 2.75-20.67 3.68-26.49 5.22-29.34 6.26-32.17 7.34-64.06 15.89L.87 61.65',
		'M598.18 296.31l-6.74-9.93-5.72-10.54-4.56-11.09-3.3-11.53-2.1-11.81-1.08-11.95-.47-33-.62-11.98-1.67-11.87-2.22-8.72-3.16-8.42-2.64-5.39-3.04-5.17-7.1-9.67-8.19-8.76-6.68-6.02-7.03-5.63-7.29-5.26-10.06-6.55-12.95-7.56-10.6-5.63-24.36-11.64-27.61-11.72-33.6-12.92-36.74-13.1-62.54-21.08-2.74-1.2-1.41-2.58-.71-2.9-.63-5.97-1.5-2.57-2.77-1.11-5.95-.72-6 .05-11.96.99-11.89 1.57-32.52 5.59-41.14 8.46-46.78 10.75-46.56 11.65L44.3 54.64 1.05 67.05',
		'M598.06 337.08l-11.83-9.22-9.09-7.83-8.65-8.31-6.13-6.59-7.6-9.28-5.18-7.36-4.66-7.7-4.06-8.02-3.43-8.32-2.74-8.57-2.06-8.76-1.43-8.89-1.18-14.95-.27-26.99-.45-11.99-1.41-11.91-2-8.78-1.87-5.69-2.32-5.54-2.77-5.32-3.17-5.09-3.55-4.84-3.87-4.58-4.14-4.34-6.64-6.08-11.9-9.11-15.23-9.59-15.9-8.44-19.1-8.71-19.54-7.7-22.74-7.65-20.24-5.58-17.64-3.55-11.93-1.26-8.99.12-5.92.95-2.88.85-5.37 2.64-2.38 1.82-2.1 2.14-3.37 4.95-1.31 2.7-2.03 5.64-2.07 8.75',
		'M598.73 340.68l-11.93-9.09-9.17-7.74-8.75-8.21-8.21-8.75-7.54-9.33-5.12-7.4-5.98-10.39-3.76-8.18-3.1-8.45-1.68-5.76-1.95-8.78-1.32-8.9-1.05-14.96-.42-32.99-.64-8.98-.78-5.95-1.85-8.8-1.75-5.74-2.2-5.58-4.12-7.99-5.01-7.47-5.76-6.92-6.35-6.37-9.17-7.73-7.28-5.3-10.08-6.5-10.43-5.94-13.38-6.77-22.01-9.56-19.71-7.24-20.07-6.17-11.63-2.95-8.81-1.84-8.89-1.43-5.96-.62-5.99-.27-6 .23-5.92.93-2.89.81-5.45 2.47-2.48 1.68-2.27 1.97-3.82 4.61-1.58 2.55-2.58 5.41-3.61 11.44-2.39 11.75-1.79 14.89-1.25 17.96-.89 23.98-.48 33',
		'M599.46 471.43l-51.81-15.22-37.18-11.78-36.84-12.79-27.95-10.88-41.15-18.22-16.2-7.85-10.62-5.57-10.42-5.96-10.09-6.5-7.24-5.33-6.87-5.82-6.34-6.38-3.87-4.59-3.53-4.85-4.72-7.66-4-8.05-2.21-5.58-1.81-5.72-2.22-8.72-1.74-8.83-1.77-11.87-2.24-23.89-1.32-29.97-.35-38.99',
		'M598.68 473.67l-46.14-13.24-43.02-13.2-36.99-12.35-30.94-11.48-13.88-5.68-24.76-10.77-27.11-12.84-10.59-5.65-10.31-6.13-7.45-5.04-7.1-5.53-6.65-6.06-6.36-6.37-6.02-6.69-5.57-7.07-3.4-4.94-4.5-7.79-2.52-5.44-2.06-5.64-2.13-8.73-.83-8.96-.17-90-5.21-2.6-2.99.21-5.79 1.53-5.67 1.96-19.46 7.91-19.03 8.86-18.54 9.87-12.79 7.83-12.19 8.72-9.08 7.85-4.19 4.29-3.86 4.59-4.94 7.51-2.5 5.45-.94 2.85-1.13 5.88-.73 11.97-1.63 8.85-3.48 11.47-4.48 11.14-6.41 13.55-9.76 18.6-16.09 28.81-15 25.98-15.38 25.76-12.68 20.37-13.15 20.08-13.72 19.69-12.53 16.85-14.96 18.77-15.64 18.2-14.21 15.46M599.01 267.6l-3.1-8.44-1.67-5.77-1.93-8.78-1.29-8.91-.99-14.96-.23-27-.37-8.99-1.32-11.92-1.89-8.8-1.77-5.73-2.19-5.59-2.61-5.4-3.02-5.18-3.37-4.96-3.7-4.73-8.17-8.78-11.3-9.85-12.12-8.84-15.24-9.56-18.42-10.09-21.56-10.52-24.67-10.98-80.52-32.95-21.98-9.62L344.79 16l-10.5-5.82-7.5-4.96-4.7-3.73M239.42.41l-20.74 3.27-23.63 4.22-32.38 6.37-29.34 6.23-35.12 7.94-32.09 7.68L2.21 52.58',
		'M598.28 346.49l-12.25-8.64-11.86-9.19-11.31-9.85-8.54-8.43-7.96-8.97-7.24-9.57-6.37-10.17-4.1-8-3.47-8.31-2.79-8.55-2.12-8.74-1.48-8.88-.91-8.95-.46-8.99-.42-33-.67-8.97-1.35-8.89-2.24-8.72-2.05-5.64-2.5-5.45-2.95-5.22-3.34-4.98-5.68-6.98-6.31-6.41-6.81-5.88-7.18-5.42-10.02-6.6-7.77-4.55-10.61-5.59-19.11-8.71-19.61-7.5-20.02-6.33-17.48-4.3-8.85-1.62-8.93-1.11-5.99-.34-6 .09-8.88 1.33-5.64 2.04-2.63 1.44-4.65 3.76-1.97 2.26-3.23 5.05-2.4 5.49-1.81 5.72-2.45 11.74-1.82 14.89-1.42 20.95-.89 26.98-.37 30',
		'M599.72 345.33l-12.15-8.79-9.39-7.46-11.22-9.96-8.43-8.53-7.82-9.1-7.05-9.71-6.13-10.31-3.89-8.11-3.3-8.38-2.77-8.56-2.1-8.75-1.45-8.88-.89-8.95-.45-8.99-.43-32.99-.69-8.98-.83-5.94-1.96-8.78-1.84-5.71-2.29-5.54-4.28-7.91-5.16-7.37-5.91-6.78-6.48-6.24-6.93-5.74-7.28-5.3-10.09-6.48-10.44-5.91-10.71-5.43-10.9-5.01-11.07-4.63-19.7-7.26-20.07-6.18-11.63-2.95-8.81-1.85-14.85-2.06-5.99-.3-5.99.17-5.94.82-2.91.74-5.52 2.31-2.53 1.62-4.35 4.11-1.79 2.41-2.85 5.27-2.94 8.5-1.35 5.84-1.48 8.88-1.57 14.91-1.21 20.97-.77 26.98-.33 33',
		'M599.44 348.36l-12.27-8.62-11.86-9.18-11.3-9.86-8.53-8.44-7.93-9.01-7.19-9.6-6.29-10.21-4.04-8.04-3.38-8.34-2.7-8.58-2.02-8.77-1.39-8.89-1.15-14.95-.55-35.99-.75-8.97-.89-5.93-2.05-8.76-1.91-5.69-3.71-8.19-3.01-5.19-3.4-4.94-5.73-6.94-6.34-6.39-6.82-5.87-7.18-5.41-10.02-6.6-7.77-4.55-10.61-5.59-19.12-8.69-19.63-7.45-20.06-6.2-17.53-4.08-14.85-2.05-5.99-.29-6 .18-5.94.82-2.91.71-2.84.95-5.34 2.72-2.42 1.77-4.22 4.26-4.95 7.5-3.65 8.22-3.1 11.58-2.31 14.81-1.61 17.93-1.14 23.97-.63 33',
		'M597.95 465.76l-43.05-13.11-39.87-13.2-30.99-11.34-27.73-11.43-40.88-18.8-13.43-6.7-13.21-7.09-10.36-6.06-12.53-8.24-9.53-7.28-6.75-5.95-6.3-6.43-3.89-4.57-3.61-4.79-4.85-7.58-4.13-7.99-2.37-5.51-3.02-8.48-2.44-8.66-1.97-8.78-2.02-11.83-1.49-11.9-1.09-11.96-1.35-26.96-.33-33',
		'M597.97 468.66l-45.98-13.76-39.95-12.96-33.9-12.13-27.81-11.23-38.27-17.31-13.47-6.59-13.28-6.98-12.99-7.5-10.1-6.47-9.67-7.1-6.85-5.84-6.38-6.34-3.93-4.54-5.31-7.25-3.13-5.12-2.78-5.32-3.54-8.27-2.85-8.54-2.26-8.71-1.8-8.81-1.82-11.86-2.29-23.89-1.24-26.97-.38-36',
		'M599.34 472.77l-46.11-13.34-42.97-13.33-36.94-12.53-30.85-11.71-19.33-8.2-21.92-9.77-24.26-11.83-10.52-5.78-10.22-6.28-9.76-6.98-6.86-5.83-6.29-6.42-5.83-6.86-5.34-7.24-3.24-5.05-4.42-7.84-2.58-5.41-3.12-8.44-1.51-5.81-1-5.91-.86-8.96-1.52-26.96-.75-29.99-.25-38.99M597.33 478.52l-49.17-13.54-40.34-11.68-40.15-12.33-34.17-11.34-25.38-9.21-22.29-8.89-24.86-10.54-32.61-15.23-11.14-4.47-8.51-2.91-11.61-3.04-11.87-1.71-5.99-.21-5.99.28-8.89 1.39-5.79 1.56-5.65 2.02-5.48 2.44-7.89 4.32-5.05 3.23-7.29 5.28-6.98 5.68-8.91 8.04-12.71 12.74-12.17 13.27-56.52 66.13-15.96 17.92-14.31 15.37-16.8 17.14-15.1 14.6-17.67 16.23-20.35 17.75',
		'M597.46 476.04l-57.73-16.35-43.05-13.07-42.72-14.16-16.92-6.12-16.79-6.5-38.65-16.42-16.32-7.59-10.69-5.46-10.42-5.95-55.58-35.59-10.36-6.05-8-4.12-8.33-3.4-8.74-2.08-5.99-.24-5.93.85-5.7 1.86-5.38 2.64-5.07 3.2-4.81 3.58-4.6 3.86-6.56 6.16-12.29 13.14-11.53 13.82-12.83 16.63-14.15 19.39-43.5 61.09-16.21 21.59-14.92 18.8-19.45 22.84-16.23 17.68-21.07 21.35-19.62 18.55',
		'M599.58 475.46l-40.43-11.4-40.25-11.98-37.16-11.85-31.17-10.83-27.97-10.84-33.04-14.29-21.65-10.35-15.73-8.75-7.54-4.91-7.24-5.34-11.79-9.27-11.44-9.7-12.94-12.51-5.96-6.74-5.44-7.16-3.24-5.06-2.81-5.29-3.13-8.43-1.17-5.88-.76-14.97-1.31-8.9-2.54-8.63-2.74-5.32-1.8-2.4-2.13-2.11-2.43-1.75-2.69-1.33-2.86-.89-5.95-.66-2.99.12-5.93.91-5.78 1.58-5.62 2.1-5.44 2.54-5.23 2.94-4.98 3.33-4.69 3.75-4.28 4.19-3.74 4.69-2.96 5.21-1.89 5.68-1.12 11.93-2.29 8.69-3.44 8.32-4.09 8.01-7.54 12.97-9.53 15.27-49.77 78.56L105 466.39l-13.46 19.87-12.17 17.12-12.6 16.79-11.19 14.11-13.5 16.08-11.97 13.44-14.4 15.29L.89 593.97',
		'M599.6 474.8l-46.16-13.14-40.2-12.16L479 438.37l-31.09-11.05-27.85-11.15-35.66-15.8-21.41-10.83-7.79-4.5-7.57-4.87-7.24-5.34-9.2-7.71-8.84-8.11-6.31-6.42-5.93-6.76-5.56-7.08-5.02-7.47-2.93-5.23-3.56-8.26-2.33-8.68-.73-5.95-.67-20.99-1.22-11.93-1.04-5.91-1.49-5.81-2.22-5.57-1.5-2.59-1.85-2.36-2.22-2.02-2.55-1.56-2.8-1.08-5.92-.88-3 .05-5.95.74-8.73 2.18-8.47 3.01-8.24 3.62-10.58 5.65-7.57 4.86-7.16 5.45-6.58 6.14-3.92 4.54-3.38 4.95-1.43 2.64-2.2 5.57-1.1 5.89-.53 8.98-1.8 8.81-3.99 11.31-5.07 10.87-7.09 13.22-12.08 20.73-29.48 48.79-18.95 30.61-14.62 22.7-13.44 19.88-12.21 17.09-12.67 16.74-13.17 16.36-13.68 15.93-14.17 15.49L.63 590.35',
		'M598.05 474.07l-43.27-12.34-43.06-13.07-34.21-11.21-31.06-11.17-13.96-5.48-16.58-6.99-32.84-14.75-10.76-5.32-10.56-5.69-7.74-4.6-7.49-4.98-7.13-5.49-6.8-5.89-6.58-6.14-6.3-6.43-5.93-6.77-3.68-4.73-6.51-10.07-2.71-5.36-2.25-5.56-1.71-5.75-1.12-5.89-.63-8.97-.42-24-.92-14.97-1.59-11.89-1.44-5.82-1.01-2.82-1.32-2.69-1.72-2.46-2.18-2.05-2.6-1.48-2.86-.88-2.97-.38h-3l-5.95.72-8.74 2.16-11.31 3.99-11 4.79-13.3 6.94-10.19 6.32-9.66 7.12-6.71 5.99-6.01 6.69-4.95 7.5-2.41 5.49-1.45 5.81-.79 11.97-1.71 8.83-3.73 11.4-4.79 11-8.2 16.02-10.27 18.31-18.16 31.09-21.61 36.01-15.87 25.46-13.14 20.09-13.64 19.74-12.43 16.92-14.83 18.87-13.52 16.07-14.02 15.63L.27 588.12',
		'M599.6 474.21l-49.03-14.04-40.16-12.29-34.19-11.29-31.01-11.27-13.93-5.55-22.07-9.45-29.97-13.8-10.67-5.48-10.45-5.91-7.6-4.81-7.3-5.26-4.64-3.81-8.84-8.11-10.33-10.87-5.65-7-3.46-4.9-3.16-5.11-2.79-5.31-2.36-5.51-1.87-5.7-1.75-8.82-.41-5.98-.23-27-.78-20.98-1.24-11.94-1.87-8.79-1.02-2.82-1.43-2.64-1.99-2.22-2.56-1.55-2.89-.78-2.99-.2-5.96.6-5.85 1.33-5.75 1.72-14.03 5.29-13.64 6.25-13.23 7.06-12.71 7.96-9.61 7.18-8.83 8.11-5.81 6.86-4.69 7.67-2.21 5.57-1.23 5.87-.77 11.96-1.73 8.83-3.67 11.42-4.68 11.05-6.64 13.44-10.02 18.46-17.9 31.23-16.77 28.43-15.6 25.62-12.88 20.25-13.35 19.94-13.92 19.55-12.71 16.72-15.17 18.6-13.81 15.81-16.37 17.55',
		'M597.34 473.43l-46.14-13.25-43.01-13.21-34.16-11.37-30.97-11.39-16.67-6.79-22.01-9.56-27.16-12.73-10.63-5.57-10.37-6.04-7.52-4.94-7.18-5.42-6.75-5.95-6.47-6.26-10.03-11.15-5.4-7.2-3.25-5.04-2.92-5.24-2.51-5.44-2.05-5.64-1.53-5.8-1.22-8.91-.6-56.99-.66-11.98-.8-5.95-.79-2.89-2.8-5.29-2.15-2.07-2.77-1.1-2.98-.28-5.95.7-11.54 3.27-14 5.39-16.36 7.5-15.87 8.48-12.71 7.96-9.68 7.1-8.97 7.95-6.02 6.69-5.04 7.44-2.57 5.42-.97 2.84-1.19 5.87-.75 11.97-1.68 8.83-3.58 11.45-4.59 11.09-7.91 16.16-9.98 18.48-31.45 54.59-17.07 28.23-12.84 20.28-13.32 19.96-13.9 19.57-12.7 16.72-15.15 18.62-13.8 15.82-14.29 15.39M599.88 254.88l-2.59-11.71-1.46-11.91-.61-11.98-.47-32.99-1.17-11.94-1.74-8.83-1.65-5.77-2.08-5.62-2.5-5.46-2.91-5.24-3.27-5.03-3.61-4.79-3.89-4.57-6.28-6.44-11.38-9.77-14.66-10.44-15.36-9.36-18.5-9.95-18.88-9.18-21.89-9.84-77.55-32.28-21.96-9.68-10.81-5.21-10.59-5.65-7.69-4.67-7.33-5.21M208.93.25l-47.24 8.49L108.8 19.6 56.13 31.54.79 45.19',
		'M599.4 352.54l-12.44-8.39-12.07-8.9-9.31-7.57-8.92-8.03-8.43-8.53-5.93-6.77-7.32-9.51-6.63-10-4.33-7.88-4.8-10.99-2.83-8.55-2.73-11.68-1.31-8.9-.94-11.96-.44-32.99-.74-11.98-.77-5.95-1.87-8.8-1.78-5.72-2.25-5.57-4.21-7.94-3.33-4.99-5.66-6.99-6.31-6.41-6.82-5.88-9.67-7.1-10.15-6.39-10.51-5.79-19.01-8.92-19.57-7.59-20.04-6.28-17.52-4.12-14.84-2.13-5.99-.33-5.99.09-5.96.68-5.81 1.47-5.46 2.47-2.5 1.66-4.36 4.1-1.83 2.38-3 5.19-2.28 5.54-1.74 5.74-2.41 11.75-1.84 14.89-1.46 20.95-.85 23.98-.4 27',
		'M597.7 352.2l-12.42-8.41-12.05-8.93-9.29-7.59-8.9-8.06-8.4-8.56-5.9-6.8-7.21-9.58-4.84-7.59-4.27-7.92-4.7-11.04-2.74-8.57-2.61-11.7-1.23-8.92-.71-8.97-.47-32.99-.93-14.97-2.08-11.81-1.64-5.77-2.1-5.62-3.99-8.06-3.19-5.08-5.47-7.14-6.15-6.57-6.68-6.02-9.53-7.29-10.06-6.54-10.44-5.92-10.72-5.38-8.2-3.71-19.55-7.67-20.03-6.29-17.53-4.03-8.9-1.36-5.97-.56-6-.2-5.99.29-5.92.94-2.9.78-5.64 2.03-5.29 2.82-2.39 1.81-4.15 4.32-1.74 2.44-2.88 5.26-3.13 8.43-2.73 11.68-2.1 14.85-1.48 17.93-.97 20.98-.57 26.99',
		'M599.37 460.93l-40.03-12.72-39.64-13.86-30.7-12.1-32.85-14.73-32.41-15.65-13.27-6.99-13.04-7.43-10.18-6.34-9.88-6.82-9.46-7.37-6.74-5.97-6.34-6.38-5.87-6.82-3.62-4.78-4.94-7.53-4.31-7.89-3.71-8.2-3.11-8.44-2.59-8.62-3.85-17.58-1.77-11.87-1.27-11.93-1.26-20.96-.39-32.99-.4-5.99',
		'M599.24 463.98l-40.11-12.46-39.77-13.5-30.86-11.7-30.28-13.1-37.97-17.94-15.96-8.33-13.05-7.4-10.21-6.29-9.91-6.78-7.15-5.46-9.02-7.9-6.29-6.44-5.77-6.9-3.52-4.86-4.74-7.65-2.8-5.3-3.65-8.23-3.04-8.47-2.5-8.64-2.04-8.77-1.64-8.85-1.69-11.87-1.23-11.94-1.38-23.96-.47-35.99-.61-2.87M598.92 484.15l-72.45-19.38-69.25-19.7-48.78-14.9-28.52-9.31-25.53-8.78-14.41-4.15-14.72-2.84-11.95-1.05-11.99.29-11.87 1.73-5.83 1.41-8.56 2.76-5.57 2.23-8.13 3.86-7.86 4.38-7.6 4.82-9.78 6.95-7.1 5.53-11.48 9.65-15.53 14.14-55.9 54.39-28.51 26.61-15.71 13.94-15.98 13.63-35.01 28.26',
		'M599.07 481.74l-46.34-12.51-37.55-10.54-37.42-10.97-31.53-9.74-22.82-7.44-22.68-7.86-19.68-7.32-36.3-14.27-17.09-5.62-11.68-2.73-11.89-1.61-11.99-.19-5.97.51-5.93.93-5.84 1.35-8.57 2.77-8.26 3.55-5.33 2.75-7.74 4.59-7.44 5.06-7.18 5.43-9.23 7.67-13.25 12.17-12.77 12.69-53.57 56.7-29.56 29.83-17.43 16.5-15.58 14.08-18.16 15.69-18.48 15.31',
		'M599.6 480.61L553.29 468l-46.16-13.18-40.2-12.17-28.55-9.21-19.86-6.82-19.71-7.25-27.87-11.1-30.33-13-11.24-4.19-8.58-2.73-8.68-2.35-5.87-1.24-11.9-1.5-6-.17-5.99.26-8.91 1.26-5.83 1.4-8.52 2.9-5.49 2.41-7.95 4.21-7.61 4.8-7.3 5.26-13.82 11.53-10.91 10.29-14.63 15.06-58.33 64.55-14.38 15.3-14.69 15.01-17.2 16.74-17.63 16.28-15.76 13.87-18.34 15.49',
		'M597.64 477.66l-46.25-12.84-46.06-13.52-45.75-14.52-31.15-10.89-25.15-9.81-33.1-14.14-16.23-7.79-21.1-11.44-16.03-8.18-10.9-5.02-11.17-4.38-11.51-3.37-8.87-1.52-8.98-.35-8.93 1.02-5.82 1.46-5.65 2.01-5.45 2.51-7.79 4.49-7.37 5.18-6.99 5.66-13.1 12.33-12.27 13.17-13.63 15.98-54.18 68.07-15.33 18.46-13.79 15.84-18.3 19.85-16.83 17.11-19.53 18.64-20.07 18.06',
		'M599.33 478.61l-52.04-14.42-46.06-13.53-40.05-12.63-31.21-10.73-22.44-8.49-33.23-13.84-16.38-7.48-32.14-16.21-10.99-4.8-11.27-4.12-5.76-1.69-8.77-2-8.9-1.31-5.99-.3-5.99.25-8.88 1.41-5.78 1.63-5.61 2.12-5.42 2.55-7.79 4.51-7.4 5.12-4.73 3.69-6.85 5.84-8.73 8.24-12.45 12.99-11.91 13.49-57.33 69.38-15.71 18.14-12.08 13.35-16.53 17.4-17.03 16.9-17.51 16.41-20.19 17.93',
		'M598.25 479.42l-52.06-14.32-37.47-10.82L468.56 442l-31.36-10.3-19.8-6.99-19.62-7.49-33.26-13.76-30.02-13.7-11.17-4.39-8.54-2.82-11.65-2.88-5.92-.95-5.97-.55-6-.09-5.99.38-5.96.64-5.89 1.12-5.79 1.59-8.4 3.2-5.41 2.6-7.8 4.48-7.45 5.05-7.15 5.47-13.52 11.88-10.68 10.53-12.33 13.11-55.42 63.12-16.17 17.74-14.48 15.21-16.97 16.97-15.23 14.46-17.8 16.08-20.48 17.6',
		'M599.58 477.48l-49.12-13.71-43.16-12.76-37.18-11.78-34.02-11.76-19.62-7.47-16.65-6.86-19.29-8.28-16.35-7.54-13.32-6.89-38.95-22.53-10.64-5.54-13.61-6.3-11.29-4.05-8.77-2.01-8.96-.68-8.94.93-5.8 1.54-5.58 2.19-5.35 2.71-5.12 3.13-4.9 3.46-7 5.65-6.65 6.06-8.44 8.53-12 13.42-11.43 13.9-16.53 21.35-41.6 55.05-16.83 21.11-13.51 16.08-15.93 17.94-18.6 19.57-19.27 18.91-19.85 18.31',
		'M597.71 475.7l-43.32-12.19-40.26-11.97-31.46-9.96-31.23-10.65-25.28-9.49-24.92-10.39-27.37-12.28-16.04-8.15-7.81-4.49-7.56-4.87-24.43-17.41-18.89-14.8-15.8-13.82-6.4-6.33-6-6.7-3.66-4.76-3.18-5.09-2.39-5.49-.74-2.9-.79-8.96-.64-2.93-.99-2.83-1.36-2.67-1.78-2.41-2.19-2.04-2.56-1.55-2.82-1-2.97-.46-2.99.04-2.97.45-5.66 1.93-5.14 3.07-4.35 4.12-1.75 2.43-1.37 2.66-.91 2.86-.54 5.97-1.47 5.8-2.48 5.46-4.61 7.72-38.19 53.83-18.5 27.33-41.44 62.51-15.41 22.16-12.38 16.97-18.43 23.67-21.39 25.12-22.55 24.09L.95 598.34',
		'M597.22 476.46l-51.98-14.61-43.11-12.9-39.96-12.92-33.89-12.14-13.96-5.5-24.88-10.49-16.43-7.35-13.48-6.57-13.11-7.28-43.51-26.61-13.14-7.22-8.13-3.86-11.24-4.18-8.73-2.16-2.97-.41-6-.22-5.96.65-5.8 1.49-5.57 2.22-5.3 2.81-5.05 3.24-7.15 5.46-4.53 3.93-6.52 6.2-8.29 8.69-11.77 13.61-13.07 16.44-58.84 79.61-14.74 18.94-15.21 18.56-17.79 20.31-16.43 17.5-19.12 19.05-19.73 18.44',
		'M599.92 476.65l-69.25-19.71-28.7-8.74-28.55-9.19-22.7-7.8-19.71-7.26-13.94-5.53-24.86-10.53-13.7-6.12-13.52-6.48-10.61-5.61-7.75-4.58-29.88-20.07-44.01-31.28-12.58-8.18-5.31-2.79-5.6-2.12-2.93-.63-2.99-.23-2.99.22-2.93.65-5.51 2.33-7.47 5.01-4.59 3.86-6.51 6.21-6.21 6.52-7.92 9.01-13.2 16.33-12.59 16.81-17.33 24.48-44.28 64.22-14.1 19.42-16.44 21.41-19.12 23.12-18.03 20.1-18.76 19.41-21.6 20.82',
		'M598.57 476.38l-57.74-16.33-51.64-15.77-42.63-14.4-16.88-6.27-16.7-6.7-35.78-15.52-13.51-6.51-13.19-7.14-22.66-14.68-44.67-30.34-7.57-4.86-7.79-4.5-5.42-2.59-5.66-1.96-8.84-1.61-5.99.26-2.93.62-5.62 2.07-5.26 2.89-4.92 3.42-4.67 3.77-6.63 6.09-8.34 8.62-5.97 6.73-11.45 13.89-12.74 16.69-15.78 21.91-43.02 61.44-17.83 24.12-14.8 18.89-17.34 20.7-18.1 20.03-20.97 21.45L6 599.43',
		'M568.77 467.34l-34.57-10.05-37.27-11.46-31.34-10.35-25.41-9.11-22.32-8.82-33.04-14.31-18.93-9.08-15.73-8.74-7.53-4.93-12.17-8.77-11.92-9.09-9.3-7.59-8.99-7.95-6.44-6.29-6.07-6.64-3.74-4.69-6.72-9.93-3.78-8.15-1.54-5.8-.81-11.96-1.61-8.84-2.07-5.63-3.16-5.08-2.07-2.16-2.4-1.81-2.66-1.37-2.85-.92-5.95-.63-5.96.65-8.6 2.6-8.01 4.09-7.19 5.39-4.14 4.34-1.79 2.4-2.81 5.29-.92 2.85-1.36 11.9-1.62 5.77-3.59 8.25-4.33 7.89-7.95 12.72-29.83 45.01-40.75 62.96-18.34 27.44-12.05 17.19-10.64 14.53-12.82 16.63-11.37 13.95-11.73 13.65-12.09 13.34-14.52 15.16-14.93 14.78',
		'M598.19 474.96l-43.29-12.27-37.37-11.18-37.14-11.9-31.15-10.89-27.93-10.94-35.74-15.6-21.53-10.6-15.53-9.09-7.37-5.17-9.46-7.37-9.22-7.69-8.86-8.08-6.32-6.41-5.94-6.76-5.38-7.21-3.18-5.09-3.91-8.09-1.89-5.7-1.37-5.84-.81-5.94-.77-17.98-1.18-8.91-2.12-8.74-2.26-5.56-1.5-2.59-1.81-2.39-2.15-2.1-2.46-1.7-2.71-1.27-2.88-.83-5.97-.56-2.99.15-5.93.9-5.81 1.49-8.46 3.06-8.15 3.82-7.79 4.5-7.33 5.21-4.56 3.9-4.19 4.29-3.7 4.72-3 5.19-2.06 5.62-.59 2.94-1 11.95-2.23 8.71-3.27 8.38-5.29 10.77-7.36 13.07L166 364.6l-55.48 89.14-14.79 22.59-13.6 19.77-12.34 16.99-12.81 16.64-13.31 16.25-13.81 15.82-14.29 15.39L.85 592.17',
		'M598.45 475.5l-43.31-12.22-43.11-12.89-31.43-10.05-31.19-10.79-25.21-9.66-35.84-15.37-21.67-10.32-15.74-8.73-7.54-4.9-7.29-5.27-18.98-14.69-13.52-11.88-10.43-10.77-5.63-7.02-3.34-4.98-2.89-5.26-2.26-5.55-1.48-5.81-.39-2.97-.33-9-1.03-8.93-2.3-8.69-2.63-5.38-3.53-4.85-2.09-2.15-2.4-1.79-2.67-1.37-2.84-.93-2.96-.53-2.99-.17-5.97.5-8.69 2.29-5.55 2.28-5.32 2.78-5.04 3.25-4.69 3.73-4.23 4.25-1.89 2.33-3.16 5.09-2.06 5.63-1.23 11.91-2.45 8.65-3.63 8.23-4.28 7.92-11 17.88-60.05 93.35-18.12 27.58-13.6 19.78-12.3 17.02-10.9 14.32-11.24 14.06-13.58 16.02-12.03 13.39-12.36 13.08L1.1 594.88',
		'M598.69 474.44l-46.16-13.17-37.33-11.29-34.24-11.1-33.92-12.07-25.06-10.04-22.02-9.54-16.37-7.49-21.36-10.92-7.77-4.55-7.53-4.93-7.19-5.41-9.12-7.8-8.74-8.22-6.2-6.52-5.8-6.88-3.56-4.82-4.78-7.63-2.7-5.35-3.14-8.43-1.82-8.81-1.02-29.97-.71-8.97-1.22-8.91-2.23-8.71-1.17-2.76-3.01-5.19-1.88-2.34-2.28-1.94-2.63-1.43-2.85-.91-5.96-.55-8.92 1.1-8.69 2.33-8.46 3.05-10.96 4.89-10.56 5.69-10.07 6.52-7.11 5.51-6.56 6.16-5.73 6.93-3.13 5.11-2.34 5.52-1.31 5.85-.5 8.98-1.52 8.86-3.67 11.42-4.82 10.98-8.32 15.96-11.92 20.83-41.75 69.41-16.02 25.36-13.26 20.01-13.78 19.65-10.73 14.45-14.87 18.83-13.57 16.04-16.1 17.79L1.78 588',
		'M597.7 474.52l-46.16-13.14-40.2-12.16-34.24-11.13-28.28-10.01-27.89-11.04-35.7-15.7-21.47-10.7-7.84-4.44-7.62-4.78-7.31-5.24-9.34-7.54-9.02-7.91-6.5-6.23-6.18-6.54-5.74-6.92-5.16-7.38-2.99-5.2-3.63-8.23-2.3-8.68-.68-5.96-.73-20.98-1.4-11.92-2.04-8.76-2.24-5.55-1.55-2.57-1.9-2.32-2.27-1.96-5.16-3.04-2.8-1.06-5.92-.89-3 .03-5.95.71-5.85 1.34-8.54 2.82-8.28 3.54-7.97 4.16-7.64 4.76-7.2 5.4-6.57 6.14-3.87 4.58-3.3 5.01-2.49 5.45-1.43 5.81-.55 8.98-1.67 8.83-3.97 11.32-5.13 10.85-7.19 13.16-12.23 20.65-28.15 46.08-22.21 35.65-14.69 22.65-11.79 17.37-13.98 19.52-12.72 16.7-13.23 16.31-13.72 15.89-16.28 17.64-12.59 12.87',
		'M323.29 355.21l-3.9-4.56-5.44-7.17-6.24-10.24-2.57-5.42-2.12-5.61-2.2-8.72-.9-8.95-.09-57-.34-20.99-.71-5.95-1.7-2.43-5.56-2.11-2.99-.06-2.97.42-8.68 2.35-11.28 4.11-16.54 7.1-16.15 7.94-15.67 8.85-12.51 8.27-9.45 7.39-6.59 6.12-5.94 6.76-4.94 7.51-2.49 5.45-1.61 5.77-1.16 14.94-1.02 5.91-1.45 5.82-3.85 11.36-4.73 11.03-9.38 18.78-14.45 26.29-22.48 38.99-18.56 30.85-11.19 17.76-13.24 20.02-10.31 14.76-12.47 16.89-11.1 14.17-11.49 13.85L14.96 569 .68 584.39M599.93 189.08l-.93-11.96-2.16-11.79-1.66-5.77-2.09-5.62-2.5-5.45-2.92-5.25-6.88-9.81-8.03-8.92-8.85-8.09-11.87-9.17-12.48-8.31-10.3-6.16-10.5-5.82-16.02-8.2-16.25-7.73-21.92-9.78-66.37-27.92-24.68-10.93-10.81-5.21-10.62-5.58-10.3-6.15-7.38-5.15M178.47.44l-44.25 8.17L93.06 17 46.19 27.32 2.4 37.68M599.95 357.15l-12.6-8.14-12.28-8.61-11.86-9.19-9.09-7.83-8.65-8.31-8.09-8.86-7.4-9.45-4.99-7.48-5.8-10.5-3.63-8.23-4.02-11.31-2.9-11.64-1.44-8.88-1.06-11.95-.57-35.99-.82-11.97-2.07-11.81-1.65-5.77-2.11-5.61-2.57-5.42-4.68-7.68-5.54-7.09-6.22-6.5-6.76-5.94-7.16-5.45-10.03-6.59-10.42-5.94-16.19-7.87-19.48-7.81-20-6.4-17.51-4.16-14.84-2.1-5.99-.31-6 .12-5.96.7-5.81 1.48-5.46 2.45-2.52 1.63-4.43 4.03-1.88 2.34-3.12 5.11-2.39 5.5-1.84 5.71-2.58 11.71-1.99 14.87-1.4 17.94-.92 20.98-.62 29.99',
		'M599.27 359.13l-12.66-8.04-12.36-8.5-9.61-7.19-11.56-9.56-8.78-8.17-8.26-8.7-7.61-9.28-6.8-9.88-4.48-7.8-5.02-10.9-3-8.48-2.94-11.63-1.46-8.88-1.1-11.95-.57-35.99-.8-11.97-2.01-11.82-1.62-5.78-2.07-5.62-3.96-8.08-3.19-5.08-5.47-7.14-6.17-6.55-6.71-6-7.12-5.49-7.46-5.04-10.32-6.11-16.1-8.04-11.05-4.69-8.41-3.18-17.14-5.49-8.72-2.26-8.8-1.85-14.87-1.98-5.98-.36-6 .05-5.97.6-5.85 1.32-5.56 2.22-2.6 1.5-2.44 1.75-4.27 4.2-1.81 2.39-3.04 5.17-3.34 8.34-2.95 11.63-2.28 14.82-1.62 17.93-.94 17.97-.71 26.99',
		'M597.98 455.23l-39.82-13.35-36.52-13.68-27.59-11.77-40.71-19.17-26.66-13.74-13.05-7.41-10.22-6.29-9.96-6.69-7.25-5.33-9.29-7.59-6.61-6.11-6.24-6.48-5.8-6.88-3.59-4.81-4.94-7.52-4.37-7.86-3.81-8.15-3.25-8.39-2.74-8.58-2.26-8.71-1.84-8.81-2.59-17.81-1.53-20.94-.68-38.98-1.02-5.9-1.74-2.3',
		'M598 458.52l-45.59-15.01-19.77-7.08-16.82-6.42-27.66-11.61-38.07-17.74-26.75-13.58-13.09-7.32-10.25-6.23-12.46-8.35-7.27-5.31-9.3-7.58-6.6-6.12-6.2-6.52-5.74-6.93-3.53-4.85-4.82-7.59-4.24-7.94-3.65-8.23-3.09-8.45-2.57-8.62-2.12-8.75-1.72-8.83-1.37-8.9-1.35-11.92-1.29-20.96-.54-35.99-.35-2.98-1-2.78M597.65 489.02l-90.03-23.32-118.77-31.97-32.03-7.94-14.79-2.43-11.97-.83-9 .12-5.98.46-11.85 1.85-5.84 1.38-8.61 2.6-8.42 3.19-8.2 3.71-7.98 4.15-7.78 4.52-17.46 11.67-11.99 9-16.34 13.2-59.13 50.86-29.98 24.94-28.25 22.31-31.17 23.45',
		'M598.02 486.51l-58.04-15.2-60.78-16.56-54.83-15.59-66.21-19.41-8.72-2.21-14.77-2.63-11.96-.94-8.99.09-5.98.47-11.84 1.94-5.82 1.45-8.56 2.77-8.34 3.38-8.1 3.93-7.85 4.38-7.64 4.77-17.07 12.21-11.7 9.39-15.91 13.71L149.5 523l-29.31 25.72-30.07 24.84-33.16 25.78',
		'M598.41 482.74l-83.94-22.85-63.32-18.61-45.71-14.67-19.83-6.91-33.75-12.51-14.33-4.42-8.77-2.03-8.87-1.52-11.94-1.16-11.99.22-11.86 1.76-5.82 1.46-8.53 2.87-8.25 3.58-5.34 2.74-7.76 4.55-7.5 4.97-7.25 5.33-9.35 7.52-13.48 11.93-13.02 12.43-54.82 55.48-28.04 27.11-17.72 16.18-15.82 13.82-16.07 13.51-18.66 15.1',
		'M599.64 485.32l-66.69-17.7-60.68-16.94-51.79-15.29-62.9-19.98-11.58-3.12-14.76-2.64-11.96-.85-14.98.63-11.85 1.88-5.82 1.45-8.56 2.78-5.57 2.23-8.14 3.82-7.89 4.33-7.65 4.74-7.43 5.08-9.6 7.19-11.62 9.49-15.75 13.89-54.58 51.44-31.12 28.21-32.06 27.13-32.94 26.05',
		'M598.74 477.46l-52.01-14.53-46-13.67-42.84-13.8L424 423.32l-30.57-12.42-27.42-12.16-13.4-6.74-26.05-14.88-13.2-7.13-13.49-6.54-11.15-4.43-8.64-2.51-8.87-1.45-9-.22-8.94.95-5.81 1.47-5.63 2.07-5.41 2.59-7.71 4.63-4.89 3.48-7.01 5.64-6.68 6.04-8.49 8.47-12.08 13.34-11.55 13.81-57.14 73.37-15.16 18.6-15.63 18.22-18.21 19.93-16.77 17.17-19.47 18.7-17.77 16.14',
		'M599.31 476.33L558.87 465l-34.54-10.14-34.38-10.67-28.47-9.45-25.41-9.14-19.53-7.71-30.33-13-21.7-10.25-15.75-8.71-19.94-13.35-24.34-17.53-18.91-14.78-18.1-15.75-8.54-8.43-4.03-4.45-3.59-4.8-1.41-2.64-.85-2.87-.18-2.99-.9-2.85-1.78-2.39-2.53-1.59-2.93-.54-2.95.46-2.69 1.31-2.2 2.03-1.45 2.61-1.04 5.88-2.77 5.31-5.38 7.21-26.35 32.71-17.93 24.05-20.58 29.53-48.65 72.13-13.94 19.53-12.59 16.81-20.68 25.71-17.8 20.31-20.66 21.75L1.6 599.25',
		'M598.99 476.02l-37.55-10.52-40.29-11.86-34.35-10.76-31.27-10.55-25.33-9.36-30.48-12.64-24.63-11.06-18.7-9.54-12.8-7.81-26.82-19.22-18.74-14.99-13.25-12.18-6.13-6.59-3.77-4.66-3.38-4.96-2.75-5.32-1.8-5.72-.87-5.93-.26-5.99-.91-5.93-1.87-5.69-1.38-2.66-1.73-2.45-2.09-2.15-2.44-1.74-2.71-1.27-2.89-.79-2.98-.33-5.97.45-5.77 1.61-5.44 2.51-2.57 1.56-4.73 3.67-4.02 4.45-2.95 5.21-.92 2.85-.89 8.94-1.56 5.78-3.74 8.18-4.55 7.76-40.52 59.52-52.79 80.18-11.9 17.3-14.03 19.47-18.3 23.76-19.28 22.99-22.34 24.29-23.38 23.28',
		'M406.63 413.67l-30.19-13.31-10.8-5.23-10.6-5.62-7.75-4.58-9.99-6.65-26.92-19.08-14.3-10.93-13.9-11.43-11.1-10.09-8.23-8.73-3.57-4.82-2.62-5.38-1.29-5.85-.52-5.97-.84-2.88-1.34-2.67-1.87-2.34-2.38-1.82-2.75-1.17-2.96-.48-2.99.14-2.92.68-2.77 1.15-2.56 1.55-2.29 1.94-1.93 2.29-1.46 2.61-.86 2.87-.67 5.95-.91 2.85-2.67 5.37-3.24 5.05-28.77 38.42-15.76 21.92-18.63 27.24-39.97 59.88-13.71 19.7-14.14 19.39-20.34 25.99-19.5 22.79-22.56 24.08L.49 599.6',
		'M449.37 430.59l-28.06-10.61-24.89-10.46-21.89-9.82-18.74-9.49-12.82-7.77-29.66-20.41-19.34-14.2-30.78-23.95-7.33-5.22-7.97-4.16-2.83-.98-2.96-.48-2.98.19-2.89.81-2.71 1.28-4.98 3.33-4.6 3.85-6.48 6.24-12.13 13.3-15.17 18.59-10.86 14.36-13.98 19.5-42.22 61.99-17.17 24.6-17.78 24.16-16.72 21.2-15.5 18.32-16.12 17.79-16.69 17.23-19.4 18.79M599.97 157.99l-3.46-8.31-4.36-7.86-5.17-7.36-7.9-9.03-6.5-6.22-9.23-7.67-9.71-7.05-12.61-8.12-10.36-6.04-13.21-7.12-13.4-6.73-16.28-7.68-30.19-13.33-57.97-24.66-24.55-11.24-18.62-9.7-7.73-4.61-7.48-5M150.97.28l-32.45 6-38.25 7.63-38.14 8.13-40.96 9.3M599.24 364.97l-17.95-10.9-14.99-9.96-12.1-8.87-11.63-9.47-11.01-10.18-8.22-8.74-7.58-9.3-6.78-9.9-5.83-10.48-4.75-11.01-3.58-11.45-1.91-8.79-1.31-8.91-.94-11.96-.44-32.99-.73-11.98-1.94-11.83-2.54-8.63-3.61-8.24-2.99-5.2-5.25-7.3-3.95-4.52-6.45-6.26-6.96-5.71-7.35-5.19-7.65-4.74-7.88-4.34-8.08-3.98-16.6-6.94-17.09-5.63-8.71-2.27-8.81-1.84-8.9-1.3-5.98-.48-6-.09-5.98.41-5.9 1.07-2.89.83-5.5 2.37-2.56 1.56-4.59 3.85-2 2.24-3.38 4.94-3.78 8.16-1.87 5.7-1.48 5.81-2.59 14.77-1.6 14.92-1.31 20.95-.77 23.99',
		'M598.29 362.18l-15.27-9.54-14.84-10.17-11.95-9.07-11.41-9.73-8.63-8.33-8.07-8.88-7.38-9.46-6.52-10.07-5.66-10.58-4.68-11.04-3.51-11.47-1.86-8.81-1.26-8.91-.88-11.96-.34-30-.59-11.98-1.67-11.88-2.3-8.69-2.1-5.62-2.58-5.42-3.02-5.18-3.44-4.91-3.8-4.64-4.11-4.37-4.38-4.11-6.95-5.7-12.39-8.45-13.09-7.33-16.33-7.55-16.84-6.35-17.25-5.11-14.68-3.1-8.92-1.17-5.99-.38-5.99.04-5.97.57-5.86 1.28-2.84.97-5.36 2.68-4.71 3.69-2.06 2.18-3.48 4.88-3.85 8.12-2.67 8.59-2.4 11.76-1.91 14.87M599.52 450.43l-33.98-11.88L532 425.47l-24.68-10.93-40.62-19.38-23.96-12.43-13.06-7.38-10.26-6.22-17.37-11.79-9.47-7.38-6.8-5.89-8.58-8.38-6-6.71-5.55-7.08-5.06-7.44-4.54-7.77-4-8.06-3.45-8.32-2.92-8.5-3.8-14.51-1.7-8.84-1.3-8.9-1.2-11.94-.54-8.99-.44-32.99-.48-8.98-1.03-5.91-1.04-2.81-1.75-2.4-2.75-.99-2.79.98-2.31 1.91-9.09 11.91-2.04 2.04',
		'M599.66 453.9l-39.72-13.62-33.6-12.92-22.02-9.54-43.38-20.54-16.05-8.16-13.19-7.14-20.58-12.35-9.93-6.73-7.23-5.36-9.32-7.55-6.72-5.99-6.37-6.36-5.97-6.73-5.51-7.11-5.01-7.48-4.46-7.81-3.91-8.11-3.35-8.35-2.84-8.54-2.36-8.68-1.91-8.79-2.68-17.8-1.52-20.94-.53-35.99-.58-5.97-.65-2.93-1.24-2.72-2.46-1.45-2.7 1.09-2.16 2.06-7.49 9.36M599.32 494.63l-168.79-42.25-29.18-6.94-26.41-5.63-17.75-2.99-14.94-1.22-9 .02-5.99.35-5.96.63-8.88 1.46-14.52 3.75-8.49 2.97-8.32 3.44-8.13 3.85-10.58 5.68-17.84 11.06-14.78 10.28-16.82 12.56-61.25 48.3-26.16 20.12-24.07 17.9-29.23 21.01',
		'M597.12 491.32l-66.87-16.98-113.26-29.36-26.21-6.48-20.49-4.61-17.7-3.25-14.93-1.33-6-.08-8.99.42-5.97.64-8.87 1.5-8.74 2.14-5.73 1.76-8.45 3.09-8.26 3.59-8.06 4.01-10.45 5.89-17.59 11.46-12.14 8.81-16.58 12.88-57.92 47.65-30.46 24.35-26.21 20.06-29 21.32',
		'M598.43 487.33l-101.52-26.81-52.04-14.43-75.02-21.34-14.51-3.8-14.77-2.59-11.96-.9-9 .11-5.98.49L301.8 420l-11.58 3.13-5.7 1.9-13.8 5.84-7.98 4.16-7.76 4.56-7.56 4.88-9.81 6.91-11.9 9.13-16.17 13.39-60.67 53.68-29.68 25.29-28.03 22.59-30.98 23.69M317.44 364.73l-19.34-14.22-16.51-12.97-16.06-13.53-17.83-16.07-4.87-3.5-2.8-.92-2.78 1.03-2.38 1.82-6.48 6.24-14.18 15.49-13.48 16.1-14.71 18.95-22.72 31.7-53.89 79.45-17.53 24.34-16.46 21.41-17.2 20.81-17.98 20.13-18.74 19.44-19.38 18.8',
		'M599.66 476.45l-40.44-11.32-37.42-11-28.66-8.88-34.16-11.34-22.58-8.14-19.54-7.69-30.34-12.99-21.7-10.23-13.2-7.13-15.11-9.77-24.58-17.2-21.57-16.24-20.86-17.13-17.79-16.12-4.23-4.25-1.69-2.47-.3-2.96-3.19-4.9-2.86-.61-2.53 1.48-2.03 5.57-1.73 2.45-27.4 31.82-13.07 16.44-10.81 14.39-22.49 31.86-50.44 74.53-13.97 19.52-14.45 19.16-16.95 21.02-17.73 20.36-18.5 19.66-21.36 21.07M599.61 144.64l-4.88-7.56-5.59-7.05-6.16-6.55-6.61-6.11-6.95-5.72-9.68-7.08-10.03-6.59-10.29-6.18-21.12-11.4L494 68.64l-30.13-13.45-52.37-22.51-24.58-11.18-21.38-10.89-15.41-9.3M123.48.21L64.56 11.54.02 25.34M599.31 370.81l-18.15-10.55-17.73-11.25-14.71-10.37-11.79-9.27-11.22-9.96-8.44-8.52-9.7-11.44-6.9-9.81-5.98-10.4-3.8-8.16-4.06-11.28-2.9-11.64-1.44-8.89-1.09-11.94-.57-36-.51-8.98-1.68-11.88-1.43-5.82-1.89-5.69-2.38-5.51-2.85-5.27-3.3-5.01-5.68-6.98-4.19-4.29-6.77-5.93-7.22-5.37-7.57-4.87-13.19-7.12-13.73-6.03-17.02-5.84-14.57-3.58-8.88-1.43-5.97-.58-6-.18-5.99.31-8.82 1.68-5.59 2.16-2.63 1.45-4.78 3.61-2.11 2.13-3.64 4.76-4.12 7.99-2.05 5.64-1.65 5.76-2.41 11.76-1.95 14.87-1.43 17.94-1.41 26.92',
		'M598.28 444.7l-30.95-11.44-27.74-11.42-40.77-19.02-29.55-14.71-23.69-12.94-20.4-12.63-9.85-6.85-7.18-5.43-13.65-11.73-8.43-8.53-5.89-6.81-5.45-7.16-4.98-7.49-4.46-7.82-3.93-8.09-3.39-8.34-2.87-8.53-3.68-14.53-1.6-8.86-1.18-8.92-1.3-17.95-.54-35.99-.98-8.94-1.59-5.78-1.31-2.69-1.82-2.38-2.43-1.72-2.91-.62-2.92.6-2.61 1.46-2.26 1.97-1.98 2.26-6.53 10.05',
		'M598.59 448.42l-33.87-12.2-27.84-11.17-30.04-13.65-35.12-16.97-23.88-12.58-13.01-7.48-10.19-6.32-17.23-12-7.07-5.58-6.8-5.88-8.64-8.33-6.15-6.57-5.75-6.93-5.28-7.28-4.78-7.62-4.26-7.93-3.71-8.2-4.13-11.26-3.91-14.48-1.74-8.83-1.33-8.9-.96-8.95-.77-11.97-.69-38.99-1.3-8.89-.92-2.85-1.4-2.65-2.1-2.11-2.83-.86-2.89.7-2.48 1.66-3.99 4.47-5.02 7.47-3.65 4.74',
		'M598.82 384.89l-23.74-12.85-15.55-9.07-17.71-11.27-14.69-10.41-14.02-11.28-11-10.19-10.17-11.02-9.08-11.93-6.3-10.21-5.29-10.77-4.17-11.24-3.02-11.61-1.55-8.87-1.21-11.93-.47-11.99-.16-24-.67-11.98-1.85-11.85-2.5-8.63-2.29-5.55-2.79-5.31-3.26-5.04-3.68-4.73-4.05-4.43-4.36-4.11-9.48-7.35-10.23-6.26-8.04-4.05-5.5-2.39-11.3-4.04-11.62-2.96-11.89-1.59-8.99.02-8.86 1.49-2.86.91-5.44 2.5-4.92 3.42-2.22 2.02-3.91 4.54-4.65 7.7-3.46 8.3-2.59 8.62-2.51 11.73-1.8 11.86-2.4 20.86-.62 2.93M598.12 499.52l-139.99-33.75-38.03-8.63-26.46-5.4-23.68-3.9-17.91-1.71-9-.12-8.98.44-14.86 1.99-8.79 1.93-8.66 2.45-8.51 2.93-8.36 3.34-8.19 3.72-10.7 5.44-18.14 10.57-15.09 9.81-17.24 12-82.23 60.34-54.03 37.9',
		'M599.39 496.86l-157.31-38.68-29.23-6.76-26.43-5.5-23.7-3.79-14.95-1.11-14.99.2-8.96.9-8.87 1.5-8.76 2.06-8.61 2.6-8.46 3.08-8.29 3.51-8.11 3.88-10.58 5.67-17.92 10.95-12.43 8.39-19.45 14.05-62.06 47.25-26.48 19.7-48.86 34.81M598.82 134.14l-3.82-4.63-6.18-6.54-6.62-6.09-6.96-5.7-7.23-5.37-9.95-6.7-10.23-6.28-10.43-5.93-18.61-9.72-24.37-11.63-74.16-32.56L393 20.46 371.67 9.47 356.27.16M95.99.2l-44.2 8.46L1.85 18.99M599.49 376.65l-20.93-11.76-15.38-9.34-15.01-9.93-14.49-10.68-11.54-9.58-12.96-12.48-9.82-11.33-7.04-9.72-6.14-10.3-5.11-10.86-3.98-11.31-2.82-11.66-1.4-8.89-1.05-11.95-.48-32.99-.62-11.98-1.75-11.87-2.4-8.67-2.21-5.57-2.7-5.36-3.17-5.09-5.53-7.09-4.13-4.36-6.71-5.98-4.76-3.66-7.47-5.01-13.13-7.25-13.75-5.99-14.21-4.76-14.61-3.38-11.91-1.39-6-.09-5.98.45-5.89 1.11-2.88.86-5.49 2.4-2.56 1.56-4.63 3.8-3.88 4.57-1.66 2.5-4 8.05-2.01 5.65-1.62 5.78-2.41 11.75-1.97 14.87-1.26 14.94-1.19 20.97',
		'M599.96 439.95l-27.97-10.85-24.78-10.7-37.94-18.03-32.11-16.26-20.96-11.69-20.33-12.76-14.6-10.52-13.78-11.57-8.58-8.38-6.03-6.68-5.64-7.02-5.19-7.35-4.69-7.68-4.18-7.96-3.64-8.23-3.11-8.45-4-14.45-1.75-8.83-1.29-8.9-1.41-17.94-.34-30-.37-8.99-1.17-8.92-1.62-5.77-1.2-2.75-1.52-2.58-1.89-2.32-2.3-1.93-2.67-1.35-2.92-.63-2.99.17-2.85.91-2.6 1.48-4.39 4.07-3.49 4.87-5.73 10.54',
		'M597.53 442.8l-30.84-11.75-22.1-9.36-40.71-19.16-26.83-13.42-21.09-11.46-17.98-10.84-17.3-11.9-14-11.31-8.75-8.21-6.17-6.54-7.76-9.15-5.35-7.24-4.87-7.57-4.35-7.87-3.82-8.15-3.29-8.38-4.31-14.36-1.93-8.79-1.47-8.88-1.06-8.93-.84-11.97-.45-33-.46-8.98-1.43-8.88-2.05-5.62-1.56-2.56-2.02-2.21-2.51-1.63-2.89-.76-2.97.22-2.8 1.08-2.48 1.67-4.12 4.35-3.32 4.99-6.09 10.33-3.81 4.5',
		'M599.18 390.73l-23.95-12.48-23.53-13.23-17.86-11.04-17.28-11.93-14.15-11.12-13.29-12.14-10.18-11-9.13-11.9-4.86-7.58-2.94-5.22-5.12-10.85-4.01-11.31-2.89-11.64-1.47-8.88-1.14-11.94-.43-11.99-.17-24-.45-8.99-1.62-11.88-2.32-8.69-3.47-8.3-2.96-5.21-3.46-4.9-3.89-4.57-4.26-4.22-4.57-3.89-4.83-3.56-10.26-6.19-10.89-5.03-11.36-3.86-8.76-2.05-5.93-.88-5.99-.43-8.98.44-5.87 1.19-2.86.92-5.42 2.54-4.87 3.49-2.18 2.06-3.81 4.63-3.11 5.13-3.6 8.24-2.63 8.6-2.51 11.73-1.75 11.87-2.26 20.88',
		'M598.91 381.12l-21.01-11.58-18.03-10.78-15.06-9.86-14.55-10.59-13.84-11.5-10.79-10.41-10.03-11.15-9.01-11.99-6.24-10.24-5.23-10.8-4.11-11.27-2.96-11.62-1.5-8.87-1.16-11.94-.44-12-.16-23.99-.72-11.98-1.93-11.84-2.59-8.61-2.35-5.52-2.84-5.28-3.31-5-3.73-4.7-4.09-4.39-4.39-4.09-4.64-3.79-7.35-5.19-10.36-6.05-13.6-6.31-14.16-4.95-8.7-2.27-5.89-1.18-11.91-1.34-9 .17-8.84 1.61-5.61 2.11-2.65 1.4-4.85 3.52-2.16 2.08-3.76 4.67-4.34 7.86-3.13 8.44-2.9 11.64-1.97 11.83-1.64 14.91-1.68 23.94',
		'M598.29 388.02l-23.83-12.68-20.79-11.99-17.72-11.27-14.68-10.41-14.02-11.29-13.1-12.34-9.96-11.21-8.87-12.08-6.3-10.21-5.31-10.76-4.2-11.23-3.08-11.6-1.99-11.83-1.05-11.95-.49-32.99-.6-11.98-1.76-11.87-2.44-8.65-2.27-5.56-2.77-5.31-3.28-5.03-3.71-4.71-4.1-4.38-4.42-4.05-9.61-7.18-10.37-6.03-8.14-3.83-5.57-2.22-11.43-3.64-11.75-2.42-5.96-.64-6-.17-8.95.87-5.79 1.54-2.79 1.1-5.23 2.92-4.62 3.82-2.03 2.21-3.52 4.85-4.08 8.01-2.96 8.49-2.19 8.73-2.09 11.81-1.48 11.91-2.03 20.9',
		'M598.28 393.53l-23.98-12.41-23.58-13.14-17.91-10.97-17.33-11.85-14.21-11.04-13.36-12.06-10.24-10.95-9.19-11.85-4.9-7.55-2.97-5.21-5.16-10.83-4.06-11.28-2.94-11.64-1.87-11.85-.96-11.95-.45-33-.43-8.99-1.57-11.89-2.26-8.7-2.13-5.61-2.66-5.37-4.93-7.52-3.85-4.61-4.22-4.26-4.54-3.92-4.81-3.58-10.26-6.21-8.14-3.84-11.3-4-8.76-2.06-8.93-1.09-8.99.19-2.97.41-5.81 1.47-5.49 2.39-4.98 3.33-2.26 1.99-3.98 4.47-4.91 7.54-3.87 8.11-2.86 8.53-2.14 8.74-4.89 29.59M599.85 505.11L468.4 474.37l-35.17-7.66-32.4-6.28-23.72-3.63-11.93-1.26-8.99-.55-14.99.21-8.96.84-8.89 1.36-8.81 1.86-8.68 2.35-8.56 2.79-11.19 4.32-8.24 3.63-10.77 5.29-20.92 11.75-17.79 11.16-17.47 11.65-78.81 54.81-47.23 31.92M598.79 126.19l-10.88-10.31-6.99-5.66-7.25-5.34-15.06-9.85-18.22-10.44-18.66-9.63-21.66-10.33-71.3-31.62-24.52-11.3L380.2 9.44 364.72.27M68.5.25L.79 13.55',
		'M598.91 434.18l-24.97-10.27-16.4-7.42-43.25-20.82-29.3-15.17-18.27-10.36-17.78-11.17-14.67-10.42-13.92-11.41-6.58-6.13-8.3-8.67-5.79-6.89-5.38-7.21-6.43-10.13-4.23-7.94-3.68-8.21-3.14-8.43-2.6-8.62-2.06-8.76-1.57-8.86-1.11-8.93-.97-14.97-.31-29.99-.8-11.97-1.74-8.82-2.07-5.63-1.4-2.65-3.63-4.76-2.24-1.99-2.51-1.63-2.75-1.21-2.9-.72-2.99-.19-2.98.38-2.85.9-2.66 1.38-2.43 1.75-2.19 2.05-3.73 4.7-4.38 7.85-4.97 10.92',
		'M599.36 438.18l-25.09-9.97-21.96-9.67-43.28-20.75-29.33-15.13-20.84-11.9-17.67-11.34-14.52-10.64-13.67-11.7-8.48-8.48-5.95-6.76-7.34-9.49-5.08-7.42-4.59-7.74-4.07-8.03-3.53-8.28-2.98-8.49-3.8-14.5-1.62-8.85-1.16-8.93-1.16-17.95-.31-30-.97-11.96-2.06-8.74-2.49-5.45-1.68-2.48-2.02-2.22-2.35-1.85-2.65-1.4-2.88-.82-2.99-.17-2.96.47-2.8 1.07-2.56 1.54-4.37 4.1-3.51 4.86-4.24 7.93',
		'M598.71 399.37l-29.45-14.88-23.75-12.85-20.64-12.23-17.53-11.57-14.44-10.75-13.68-11.69-6.47-6.25-6.16-6.56-9.46-11.63-8.23-12.54-5.51-10.65-4.47-11.13-2.63-8.61-1.41-5.83-2.03-11.82-1.12-11.95-.42-11.99-.17-24-.47-8.98-1.1-8.93-2.04-8.76-3.22-8.4-4.47-7.8-5.62-7.02-4.28-4.2-4.64-3.81-7.48-4.99-7.99-4.14-5.54-2.29-5.69-1.9-8.76-2.04-8.95-.88-8.97.59-5.81 1.45-2.8 1.08-5.24 2.9-4.61 3.84-3.88 4.56-3.18 5.08-3.7 8.2-1.91 5.69-2.25 8.71-1.73 8.83-3.22 20.75-1.26 2.52',
		'M597.79 396.63l-26.7-13.67-23.64-13.04-17.97-10.85-17.44-11.71-14.32-10.9-13.51-11.89-10.41-10.79-9.41-11.67-8.37-12.45-5.69-10.56-4.64-11.06-1.91-5.68-2.35-8.69-2.18-11.8-1.23-11.93-.5-11.99-.24-27-.55-8.98-1.22-8.91-2.19-8.73-2.1-5.61-2.65-5.38-4.96-7.5-5.99-6.71-4.47-4.01-4.76-3.64-7.6-4.81-10.79-5.23-11.36-3.85-8.8-1.86-8.96-.79-8.97.61-5.82 1.41-2.81 1.05-5.28 2.83-4.67 3.76-2.06 2.17-3.59 4.81-2.93 5.23-3.38 8.33-2.51 8.65-1.89 8.79-3.79 26.73-.67 2.92M598.7 510.02l-76.01-17.54-52.7-11.75-35.25-7.31-29.51-5.39-23.76-3.41-8.95-.93-11.97-.76-15 .14-8.97.76-8.91 1.25-8.83 1.73-8.73 2.18-11.46 3.56-8.45 3.1-11.07 4.62-8.16 3.8-21.25 11.15-20.65 12.22-22.78 14.49-74.95 49.82-42.68 27.93M598.08 118.3l-11.58-9.53-12.26-8.64-15.3-9.47-18.38-10.14-18.76-9.45-18.99-8.97-65.73-29.37-24.49-11.38-21.4-10.86-15.61-8.95M41.01.34L2.72 7.74',
		'M597.97 428.4l-24.7-10.89-45.94-22.16-26.66-13.74-23.51-13.29-20.22-12.91-16.9-12.46-13.61-11.77-6.39-6.33-6.07-6.65-9.25-11.81-7.96-12.69-4.1-8.02-2.42-5.49-4.97-14.14-3.44-14.6-2.03-14.85-.89-14.97-.44-33-1.28-11.92-2.28-8.7-2.45-5.47-3.33-4.98-4.23-4.24-5.03-3.24-2.76-1.17-5.85-1.26-3-.04-2.97.39-2.89.8-5.29 2.79-4.46 3.99-1.92 2.31-3.27 5.02-3.9 8.11-5.48 13.96',
		'M598.69 432.94l-16.63-6.9-13.7-6.09-37.91-18.09-32.12-16.24-23.6-13.12-20.34-12.74-17.02-12.29-6.98-5.68-8.93-8.02-6.35-6.36-6.02-6.7-9.12-11.9-4.86-7.57-2.95-5.22-6.47-13.54-2.23-5.56-2.88-8.53-3.6-14.55-1.47-8.88-1.02-8.94-.84-14.98-.31-29.99-.63-8.98-.91-5.93-1.48-5.81-2.26-5.55-3.2-5.07-4.2-4.26-5.12-3.1-2.83-.99-2.94-.56-3-.1-2.97.39-2.87.85-2.71 1.29-2.49 1.67-4.31 4.15-3.49 4.88-4.13 7.98-5.94 13.77',
		'M599.18 405.21l-29.58-14.64-26.55-13.95-20.84-11.91-17.76-11.2-17.11-12.17-9.38-7.48-6.79-5.91-6.53-6.19-6.25-6.48-9.61-11.5-8.42-12.41-4.38-7.86-2.6-5.41-4.42-11.15-1.8-5.72-2.2-8.73-2.02-11.82-1.12-11.95-.44-11.99-.16-24-.46-8.99-1.1-8.93-2.06-8.75-3.29-8.37-4.6-7.72-5.81-6.86-6.79-5.9-4.95-3.38-7.94-4.23-8.41-3.2-8.75-2.06-8.96-.67-5.98.5-5.82 1.42-5.47 2.45-4.89 3.45-4.18 4.29-3.46 4.9-4.03 8.04-2.08 5.63-2.47 8.65-5.3 26.46M597.56 514.93l-76.08-17.21-41.04-8.93-41.16-8.35-29.54-5.22-26.75-3.64-11.96-1.06-8.98-.44-15 .23-8.96.78-8.92 1.25-8.83 1.7-8.75 2.12-8.64 2.52-11.34 3.91-19.37 8.09-10.82 5.2-13.29 6.95-20.86 11.87-23.05 14.06-111.28 70.99M599.14 112.51l-11.99-9.02-12.53-8.25-12.89-7.66-18.48-9.97-32.36-15.76-84.67-38.48-24.14-12.08L383.8.95M13.53.47L1.74 2.72',
		'M599.91 423.74l-32.61-15.25-37.68-18.55-26.49-14.07-18.15-10.57-20.1-13.09-16.76-12.65-13.46-11.95-6.31-6.42-5.97-6.73-9.06-11.94-7.76-12.83-5.14-10.84-3.2-8.41-1.81-5.72-3.43-14.6-1.99-14.86-.82-14.97-.32-30-.65-8.98-.88-5.93-2.31-8.69-2.38-5.5-3.15-5.1-1.86-2.36-4.26-4.21-4.91-3.44-2.65-1.4-5.63-2.06-2.93-.63-5.98-.35-2.98.32-5.74 1.7-2.67 1.36-2.49 1.67-4.38 4.08-1.89 2.33-3.25 5.04-2.65 5.38-2.23 5.57-5.77 17.05',
		'M597.92 427.35l-21.89-9.84-45.9-22.23-26.62-13.83-23.43-13.41-20.13-13.07-7.3-5.26-9.45-7.39-13.43-11.98-6.28-6.45-5.93-6.77-8.97-12.01-4.76-7.64-2.88-5.26-3.87-8.12-2.26-5.56-4.75-14.22-3.33-14.62-1.92-14.87-.78-14.98-.34-30-.69-8.97-.93-5.92-2.47-8.65-2.56-5.42-3.38-4.95-4.19-4.28-4.91-3.43-2.69-1.34-5.7-1.81-2.97-.43-3-.07-5.9.99-5.44 2.49-2.45 1.73-4.26 4.21-1.83 2.37-3.14 5.11-3.73 8.19-6.28 16.86',
		'M599.68 411.04l-32.35-15.78-26.69-13.7-23.6-13.12-20.41-12.61-17.21-12.04-14.03-11.27-6.69-6.02-6.41-6.31-6.1-6.62-5.73-6.94-6.99-9.75-6.11-10.32-5.14-10.84-4.1-11.27-1.65-5.77-1.97-8.78-2.09-14.85-.84-14.97-.47-33-.84-8.96-1.03-5.9-2.49-8.64-3.84-8.13-5.22-7.32-6.43-6.28-4.83-3.56-7.87-4.34-8.45-3.08-5.86-1.26-5.97-.53-5.99.34-5.84 1.34-5.47 2.43-4.88 3.49-4.13 4.33-3.42 4.93-2.78 5.31-2.27 5.55-2.71 8.58-4.67 20.47-.89 2.87M599.36 520.5l-67.34-15.02-49.87-10.68-35.31-7.03-32.5-5.68-26.76-3.64-20.93-1.62-9-.16-8.99.29-8.97.75-8.92 1.19-8.85 1.63-8.77 2.03-8.67 2.4-11.4 3.74-11.22 4.26-11.04 4.7-21.6 10.46-23.7 12.92-25.86 15.2-102.07 63.12M599.91 106.67l-9.82-6.89-12.68-8.02-15.63-8.93-15.93-8.37-32.42-15.64-57.37-26.05-24.43-11.48-21.41-10.84L391.94.11',
		'M599.21 417.93l-37.83-18.24-29.46-14.88-23.69-12.93-20.55-12.4-17.36-11.81-9.55-7.26-6.94-5.73-13.15-12.29-6.12-6.59-5.76-6.92-8.65-12.25-5.93-10.42-4.96-10.92-3.93-11.34-3.45-14.59-1.98-14.86-.79-14.98-.33-30-.68-8.97-.9-5.93-2.29-8.7-3.71-8.18-3.33-4.98-3.99-4.48-2.22-2.02-4.81-3.57-5.26-2.88-5.6-2.15-5.85-1.31-5.98-.36-5.94.74-5.66 1.96-2.63 1.44-2.46 1.71-4.35 4.13-3.58 4.8-2.93 5.24-2.4 5.49-2 5.66-5.87 20.15',
		'M598.88 415.2l-37.77-18.36-24.04-12.29-23.64-13.04-20.46-12.53-17.26-11.97-9.47-7.37-6.86-5.82-6.62-6.1-6.33-6.4-11.78-13.6-8.62-12.26-5.91-10.44-4.93-10.94-3.87-11.35-2.83-11.66-2.17-14.83-.92-14.97-.44-33-.76-8.96-.96-5.92-2.39-8.67-3.78-8.16-5.23-7.31-6.52-6.18-7.53-4.91-5.45-2.5-5.71-1.83-5.89-1.08-5.99-.22-5.94.8-5.67 1.93-2.65 1.4-2.5 1.67-4.43 4.03-3.67 4.74-3 5.19-2.45 5.47-2.03 5.65-2.47 8.65-2.82 11.67-1.99 5.64M598.25 525.42l-64.45-14.19-52.85-11.11-38.29-7.38-26.63-4.48-26.76-3.56-20.93-1.61-9-.19-9 .24-8.97.69-8.93 1.11-8.87 1.52-11.7 2.64-8.68 2.39-11.42 3.68-11.25 4.17-11.08 4.6-21.74 10.18-21.26 11.13-28.73 16.23-33.56 19.87-61.6 37.27M597.93 99.04l-10.11-6.45-10.34-6.1-23.78-12.78-32.39-15.7-76.28-35.18-21.47-10.73L402.57.48',
		'M597.16 530.34l-64.49-14.03-49.94-10.36-38.31-7.28-29.6-4.88-23.8-3.09-20.93-1.66-17.99-.08-8.98.59-8.95 1.01-11.84 1.95-8.79 1.91-8.72 2.24-11.48 3.48-19.72 7.22-11.06 4.65-13.64 6.24-21.46 10.73-29.01 15.74-36.43 20.89-56.83 33.57M598.18 93.17l-20.76-12.05-21.28-11.09-24.3-11.77-78.88-36.69-21.43-10.81L413.15.6',
		'M599.01 535.9l-67.45-14.54-49.96-10.24-35.38-6.65-29.61-4.86-26.78-3.43-20.93-1.57h-18l-8.98.61-8.94 1.01-8.89 1.39-11.75 2.42-8.73 2.2-11.51 3.4-11.36 3.86-11.21 4.26-22.04 9.5-24.29 11.8-31.8 16.87-36.63 20.54-46.74 27.04M598.25 87.29l-18.36-10.21-18.68-9.58-27.03-13.01-70.66-33.04-21.42-10.81L423.69.53',
		'M597.93 540.82l-61.6-13.2-44.08-9.06-38.3-7.32-35.51-5.94-26.77-3.5-20.93-1.7-17.99-.22-17.95 1.35-8.91 1.24-11.79 2.21-8.77 2.04-11.57 3.18-11.43 3.64-11.29 4.05-22.21 9.09-27.18 12.71-29.36 15.07-36.88 20.09-44.38 25.12M598.17 81.42l-18.53-9.89-18.79-9.38-89.51-42.3-21.38-10.9L434.19.27',
		'M599.8 546.38l-58.68-12.53-47.02-9.65-38.31-7.33-32.53-5.53-29.72-4.05-20.92-1.86-17.99-.45-17.96 1.04-11.9 1.53-8.87 1.55-8.8 1.87-11.63 2.96-11.5 3.43-11.37 3.83-11.24 4.19-13.89 5.66-27.33 12.38-29.53 14.73-37.08 19.72-39.35 21.83M597.97 75.55l-32.13-16.24-84.02-39.86-18.73-9.52-15.82-8.58',
		'M598.73 551.31l-58.69-12.48-49.96-10.2-38.32-7.25-32.55-5.45-23.78-3.26-23.89-2.22-17.99-.49-17.97.98-11.91 1.46-8.87 1.49-11.74 2.47-11.63 2.97-11.5 3.42-11.38 3.81-22.41 8.58-27.47 12.06-29.68 14.41-31.97 16.56-39.55 21.46M597.67 69.68L570.8 56.34l-75.86-36.08-18.74-9.47L457.72.82',
		'M597.68 556.23l-61.64-13.05-47.03-9.57-38.32-7.26-32.55-5.46-26.75-3.64-20.91-1.92-17.98-.56-17.98.89-8.94.98-11.86 1.83-20.52 4.47-20.16 5.86-11.36 3.86-14.05 5.26-24.91 10.41-29.91 13.92-32.18 16.15-39.76 21.08M599.97 65.2l-24.23-11.9-67.7-32.28-21.42-10.82L468.14.23',
		'M599.55 561.79l-58.69-12.43-52.91-10.8-38.32-7.28-29.57-5.04-26.73-3.8-23.88-2.36-17.99-.64-17.97.79-8.96.91-11.87 1.73-20.55 4.28-11.6 3.08-11.49 3.48-22.64 7.94-25.02 10.14-27.34 12.34-32.35 15.8-37.28 19.34M599.55 59.35l-81.12-38.97-18.75-9.45-18.52-9.9',
		'M598.5 566.72l-108.66-22.65-35.35-6.82-35.47-6.17-23.74-3.5-23.85-2.61-17.98-.95-17.99.42-11.95 1.05-8.92 1.17-20.64 3.87-11.65 2.86-11.55 3.26-22.78 7.54-25.17 9.76-27.5 11.99-32.52 15.45-34.77 17.65M599.08 53.5l-70.29-33.8-18.74-9.48L491.53.31',
		'M597.45 571.65L482.91 547.8l-32.41-6.24-32.5-5.7-23.73-3.58-23.84-2.73-17.97-1.07-17.99.29-11.96.95-8.93 1.09-20.68 3.67-11.68 2.74-11.58 3.13-22.86 7.31-25.27 9.51-27.61 11.72-27.23 12.6-34.95 17.29M598.58 47.65l-59.45-28.66-18.73-9.5-15.88-8.48',
		'M599.34 577.2l-111.59-23.34-35.33-6.92-35.43-6.35-26.69-4.08-23.84-2.73-17.97-1-15 .22-11.96.84-8.95 1.01-20.71 3.46-20.43 4.85-22.98 6.9-22.6 8.05-27.81 11.26-32.83 14.77-32.38 15.73M598.06 41.81l-48.6-23.54-18.72-9.52L514.87.26',
		'M598.29 582.13l-114.51-23.99-41.22-8.07-26.57-4.78-26.67-4.2-23.83-2.89-14.96-1.05h-17.99l-8.99.49-11.94 1.16-20.74 3.25-20.48 4.63-23.05 6.67-22.69 7.83-25.13 9.86-30.26 13.17-32.55 15.39M597.53 35.97l-40.44-19.74L527.83.97',
		'M597.24 587.06L479.81 562.4l-35.32-7-32.46-5.95-26.66-4.26-20.83-2.62-14.95-1.18-18-.17-17.96 1.14-20.8 2.83-20.57 4.23-23.16 6.27-22.8 7.48-28.05 10.65-27.61 11.72-29.97 13.81M599.71 31.43L564.73 14.2 538.15.28',
		'M599.13 592.61l-117.41-24.76-38.24-7.69-29.48-5.55-26.62-4.48-23.78-3.24-14.94-1.31-18-.34-17.97.96-20.83 2.62-20.61 4.02-23.22 6.03-22.87 7.26-25.35 9.31-27.74 11.42-27.37 12.29M599.21 25.58l-29.53-14.73-18.58-9.79',
		'M598.09 597.54l-123.28-26.06-41.16-8.31-26.54-5-26.62-4.49-20.8-2.87-14.95-1.26-11.99-.34-17.99.57-20.88 2.18-20.68 3.62-23.33 5.62-22.98 6.91-25.47 8.97-25.09 9.95-27.52 11.96M598.73 19.74L561.41.49',
		'M585.31 599.99L459.1 573.26l-61.82-12.12-23.66-3.99-17.83-2.46-14.95-1.22-12-.31-17.98.59-20.88 2.18-20.69 3.6-20.44 4.79-20.19 5.79-22.76 7.61-25.24 9.57-27.67 11.61M598.29 13.89L571.7 0',
		'M560.78 599.93L443.4 575l-55.94-10.95-20.71-3.49-14.86-2.04-11.95-1.01-14.99-.45-17.99.62-17.91 1.77-17.78 2.82-20.52 4.42-20.27 5.47-22.87 7.29-25.36 9.27-25.01 10.16M597.9 8.04l-13.26-7',
		'M536.25 599.85l-105.62-22.54-50.03-9.87-20.7-3.54-11.9-1.56-14.95-1.14-12-.27-17.98.63-17.91 1.77-20.73 3.34-17.59 3.83-20.29 5.41-20.04 6.27-19.81 6.97-25.15 9.81M597.57 2.19L594.93.77',
		'M511.73 599.74l-93.86-20.16-32.34-6.56-20.64-3.85-11.85-1.93-11.92-1.39-11.97-.81-11.99-.25-17.99.63-17.91 1.75-17.78 2.77-17.63 3.65-17.45 4.42-20.13 5.98-22.71 7.74-19.64 7.43',
		'M487.21 599.59l-87.97-19.02-41.2-8.15-8.89-1.4-11.92-1.36-11.98-.79-11.99-.24-14.99.45-17.93 1.57-17.81 2.58-17.66 3.47-17.49 4.26-17.31 4.93-19.98 6.47-19.75 7.12',
		'M462.68 599.39l-73.26-16.04-35.28-7.15-11.87-1.76-11.94-1.19-11.98-.64-9-.12-14.99.45-14.95 1.22-14.88 1.93-17.71 3.16-17.56 3.96-17.38 4.67-17.21 5.28-19.87 6.8',
		'M441.09 599.79l-55.63-12.42-32.28-6.83-11.86-1.87-11.92-1.31-8.98-.62-11.99-.36-15 .29-14.96 1.06-14.89 1.78-14.81 2.42-14.69 3-17.48 4.29-17.31 4.93-17.14 5.51',
		'M416.57 599.46l-58.51-13.3-11.78-2.25-11.88-1.7-11.94-1.16-11.99-.62-9-.11-11.99.29-14.96 1.05-14.9 1.75-11.85 1.86-14.73 2.86-14.61 3.39-14.49 3.87-14.38 4.28',
		'M394.98 599.68l-37.97-8.94-17.68-3.32-11.9-1.55-8.96-.81-20.99-.72-11.99.29-11.98.77-11.93 1.22-11.89 1.64-23.58 4.43-26.19 6.55',
		'M373.38 599.77l-23.34-5.59-17.73-3.08-17.9-1.89-20.98-.72-20.98.8-20.88 2.19-20.72 3.43-20.51 4.5',
		'M351.89 599.73l-14.71-2.94-14.84-2.14-17.94-1.49-14.99-.36-15 .41-17.94 1.43-14.87 1.93-17.73 3.1',
		'M321.76 599.65l-17.92-1.71-17.99-.57-17.99.51-20.91 1.88',
	];

	const springConfig = {
		stiffness: 40,
		damping: 1.5,
		restSpeed: 0.01,
	};

	const springConfig2 = {
		stiffness: 40,
		damping: 1,
		restSpeed: 0.001,
	};

	const dragYDiff = useMotionValue(0);
	const dragXDiff = useMotionValue(0);
	const y = useTransform(dragYDiff, [100, 890], [0.95, 1.05]);
	const x = useTransform(dragXDiff, [0, 1900], [0.97, 1.02]);
	const xForColor = useTransform(dragXDiff, [0, 1900], [0, 1]);
	const color = useTransform(xForColor, [0, 1], ['#DAFFF0', '#FFEEB8']);
	const opacity = useTransform(dragYDiff, [120, 840], [1, 0.1]);
	const dx = useSpring(x, springConfig);
	const dy = useSpring(y, springConfig);
	const dOpacity = useSpring(opacity, springConfig2);

	const masterVolume = useTransform(dragYDiff, [120, 840], [-3, -30]);
	const masterVolumeSpring = useSpring(masterVolume, springConfig2);
	let activeVoice = useRef(0);

	useLayoutEffect(() => {
		if (passedElRefs[0] && passedElRefs[0].current) {
			passedElRefs.forEach((ref, i) => {
				const node = ReactDOM.findDOMNode(
					passedElRefs[i].current
				) as HTMLDivElement;

				node.addEventListener('mousedown', async (e) => {
					if (Tone.context.state === 'suspended') {
						await Tone.start();
					}
					activeVoice.current = i;
					voiceContainer.voices[i].playNote();
				});

				node.addEventListener('mousemove', (e) => {
					if (e.buttons == 1) {
						dragYDiff.set(e.screenY);
						dragXDiff.set(e.screenX);
					}
				});
			});
		}
	}, [passedElRefs]);

	useEffect(() => {
		masterVolumeSpring.onChange((latest) => {
			voiceContainer.master.set({ volume: latest });
		});
	}, []);

	return (
		<div id="backgroundSVG">
			<motion.svg viewBox="30 20 550 310">
				<g fill="none" strokeWidth=".4">
					{pathArr.map((path, index) => {
						return (
							<motion.path
								style={{
									scaleY: dy,
									scaleX: dx,
									stroke: color,
									opacity: dOpacity,
								}}
								key={path.slice(10, 20)}
								d={path}
							/>
						);
					})}
				</g>
			</motion.svg>
		</div>
	);
};

export default React.memo(BackgroundSvg);
