import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import * as Tone from 'tone';

type LetterProps = {
	letter: string;
	passedRef: RefObject<HTMLDivElement>;
	channel: Tone.Channel;
};

const letterStyleDefault = {
	fontFamily: 'Diwan Kufi',
	fontSize: '100',
	stroke: 'white',
};

export const Letter = ({ letter, passedRef, channel }: LetterProps) => {
	const viewBox = (letter: string) => {
		switch (letter) {
			case 'r':
				return '-20 -75 75 100';

			case 'j':
				return '-25 -85 75 120';
			default:
				return '-15 -75 75 100';
		}
	};

	const divRef = useRef<HTMLDivElement>(null);
	const [initialX, setInitialX] = useState<number>();
	const y = useMotionValue(0);
	const x = useMotionValue(0);
	const voicePanning = useTransform(
		x,
		initialX ? [0 - initialX, 1820 - initialX] : [0, 1820],
		[-1, 1]
	);

	const textVariant = {
		tap: {
			fill: 'white',
			scale: 1.1,
			transition: {
				duration: 0.3,
			},
		},
	};

	const dragDivVariant = {
		hover: { cursor: 'pointer' },
		tap: { cursor: 'grabbing' },
	};

	useLayoutEffect(() => {
		if (divRef) {
			setInitialX(divRef.current?.getBoundingClientRect().x);
		}
	}, []);

	useEffect(() => {
		voicePanning.onChange((latest) => {
			channel.set({ pan: latest });
		});
	}, []);

	return (
		<motion.div
			ref={divRef}
			drag
			dragConstraints={passedRef}
			dragPropagation={true}
			variants={dragDivVariant}
			whileDrag="drag"
			whileTap="tap"
			whileHover="hover"
			style={
				letter === 's'
					? { marginLeft: '80px', width: '5vw', y: y, x: x }
					: { width: '5vw', y: y, x: x }
			}>
			<svg viewBox={viewBox(letter)}>
				<motion.text
					variants={textVariant}
					style={letterStyleDefault}
					fill="black">
					{letter}
				</motion.text>
			</svg>
		</motion.div>
	);
};
