import React, { RefObject } from 'react';

import { Letter } from './Letter';
import VoiceContainer from '../utils/voiceContainer';

type NameProps = {
	passedRef: RefObject<HTMLDivElement>;
	elRefs: RefObject<HTMLDivElement>[];
	nameArr: string[];
	voiceContainer: VoiceContainer;
};

export const Name = ({
	passedRef,
	elRefs,
	nameArr,
	voiceContainer,
}: NameProps) => {
	return (
		<div id="nameContainer" style={{ display: 'flex' }}>
			{nameArr.map((letter, index) => {
				return (
					<div key={`${letter} ${index}`} ref={elRefs[index]}>
						<Letter
							letter={letter}
							passedRef={passedRef}
							channel={voiceContainer.voiceChannels[index]}
						/>
					</div>
				);
			})}
		</div>
	);
};
