import React, { createContext, ReactElement, useEffect } from 'react';
import { TViewport } from './types';
import * as _ from 'lodash';

export const viewportContext = createContext<TViewport | undefined>(undefined);

export const ViewportProvider = ({ children }: { children: ReactElement }) => {
	const [width, setWidth] = React.useState(window.innerWidth);
	const [height, setHeight] = React.useState(window.innerHeight);

	const handleWindowResize = _.throttle(() => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}, 100);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	return (
		<viewportContext.Provider value={{ width, height }}>
			{children}
		</viewportContext.Provider>
	);
};

// inspiration: https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
// && https://betterprogramming.pub/usebreakpoint-hook-get-media-query-breakpoints-in-react-3f1779b73568
