import { Box, Typography } from '@mui/material';

export const NameAlt = (props: { className: string; id: string }) => {
	return (
		<Box
			className={props.className}
			id={props.id}
			sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ marginTop: '50%' }}>
				<Typography variant="h2">Artur {<br />} Szerejko </Typography>
			</Box>

			{/* <Typography variant="h2">Szerejko</Typography> */}
		</Box>
	);
};
