import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Tooltip } from '@mui/material';

export const HelpIcon = () => {
	return (
		<Tooltip
			title={`the letters are draggable... left-click & hold while moving the cursor around`}
			placement="left">
			<QuestionMarkIcon
				id="helpIcon"
				sx={{ color: 'white' }}
				fontSize="large"
			/>
		</Tooltip>
	);
};
