import { useFormik } from 'formik';
import * as yup from 'yup';
import emailjs from '@emailjs/browser';
import {
	Box,
	Container,
	TextField,
	Typography,
	Button,
	Alert,
} from '@mui/material';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import './styles.scss';

type FormValues = {
	subject: string;
	user_name: string;
	user_email: string;
	message: string;
};

export const Contact = () => {
	const [showSuccess, setShowSuccess] = useState(false);

	const toggleSuccessAlert = () => {
		setShowSuccess(true);
		setTimeout(() => {
			setShowSuccess(false);
		}, 5000);
	};

	const formik = useFormik({
		//we have created our initailValues object in a format EmailJS accepts
		initialValues: {
			subject: '',
			user_name: '',
			user_email: '',
			message: '',
		},
		validationSchema: yup.object({
			subject: yup.string().required('* Subject is required'),
			user_name: yup.string().required('* Your name is required'),
			user_email: yup
				.string()
				.email('Invalid email address')
				.required('* Your email address is required'),
			message: yup.string().required('* Your message is required'),
		}),
		onSubmit: (values) => {
			try {
				emailjs.send(
					'service_24j2jjh',
					'template_ggg9o9h',
					values,
					'user_rXEFiSNNXDEmyoMh01d9R'
				);

				formik.resetForm();
				toggleSuccessAlert();
			} catch {}
		},
	});

	return (
		<Container>
			<Box
				sx={{
					marginTop: '8vh',
					marginBottom: '6.5vh',
				}}>
				<Typography variant="h2">Get In Touch</Typography>
			</Box>

			<form onSubmit={formik.handleSubmit}>
				<Box
					display="flex"
					justifyContent="space-between"
					marginBottom="4vh">
					<TextField
						id="subject"
						name="subject"
						label="subject"
						sx={{ width: { xs: '27vw', md: '15vw' } }}
						value={formik.values.subject}
						onChange={formik.handleChange}
						error={
							formik.touched.subject &&
							Boolean(formik.errors.subject)
						}
						helperText={
							formik.touched.subject && formik.errors.subject
						}
						variant="outlined"></TextField>
					<TextField
						id="user_name"
						name="user_name"
						label="name"
						sx={{ width: { xs: '25vw', md: '15vw' } }}
						value={formik.values.user_name}
						onChange={formik.handleChange}
						error={
							formik.touched.user_name &&
							Boolean(formik.errors.user_name)
						}
						helperText={
							formik.touched.user_name && formik.errors.user_name
						}
						variant="outlined"></TextField>
					<TextField
						id="user_email"
						name="user_email"
						label="email"
						sx={{ width: { xs: '25vw', md: '15vw' } }}
						value={formik.values.user_email}
						onChange={formik.handleChange}
						error={
							formik.touched.user_email &&
							Boolean(formik.errors.user_email)
						}
						helperText={
							formik.touched.user_email &&
							formik.errors.user_email
						}
						variant="outlined"></TextField>
				</Box>

				<TextField
					fullWidth
					id="message"
					name="message"
					label="message"
					multiline
					rows={4}
					sx={{ marginBottom: '4vh' }}
					value={formik.values.message}
					onChange={formik.handleChange}
					error={
						formik.touched.message && Boolean(formik.errors.message)
					}
					helperText={formik.touched.message && formik.errors.message}
					variant="outlined"></TextField>

				<Button
					variant="outlined"
					type="submit"
					sx={{ marginBottom: '4vh' }}>
					Submit
				</Button>
			</form>

			<AnimatePresence>
				{showSuccess && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.4 }}>
						<Alert variant="outlined" severity="success">
							message sent successfully
						</Alert>
					</motion.div>
				)}
			</AnimatePresence>
		</Container>
	);
};
